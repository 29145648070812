<template>
  <div class="landing-wrapper">
  <Header />
    <main class="page-customer landing-sub">
      <div class="sub-top">
        <PageHeader title="고객 안내" subtitle="무엇을 도와드릴까요?" imageName="customer-header" />
        <TabNavigation :modelValue="activeTab" @update:modelValue="handleTabChange" :tabs="customerSections" mode="switch" />
      </div>
        <div class="sub-contents">
          <router-view></router-view>
        </div>
    </main>
  <BtnInquiry :is-landing="false" />
  <BtnTop :is-landing="false" />
  <Footer />
  </div>
  </template>
  
  <script>
  import Footer from "../components/Footer.vue";
  import Header from "../components/Header.vue";
  import PageHeader from "../components/PageHeader.vue";
  import TabNavigation from "../components/TabNavigation.vue";
  import BtnTop from "../components/buttons/BtnTop.vue";
  import BtnInquiry from "../components/buttons/BtnInquiry.vue";
    
  export default {
    name: "Customer",
    components: {
    Header,
    Footer,
    PageHeader,
    TabNavigation,
    BtnInquiry,
    BtnTop,
    },
  data() {
    return {
    customerSections: [
        { id: "notice", label: "공지사항" },
        { id: "faq", label: "자주 묻는 질문" },
        { id: "library", label: "자료실" },
        { id: "qna", label: "1:1 문의" },
      ],
    };
  },
  computed: {
      activeTab() {
      return this.$route.name;
      },
    },
  methods: {
    handleTabChange(newTab) {
          const routeName = newTab === "notice" ? "notice" : newTab;
          if (this.$route.name !== routeName) {
          this.$router.push({ name: routeName }).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    }
    },
  },
};
</script>
  
<style scoped>
.tab-navigation.switch-mode .tab-navigation-list .tab-navigation-item {
  cursor: pointer !important;
}
</style>