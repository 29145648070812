<template>
  <div class="search-container">
    <input
      :type="type"
      class="form-control"
      :value="value"
      @input="$emit('input', $event.target.value)" 
    />
    <span class="search-icon" @click="toggleType">
      <IconEye />
    </span>
  </div>
</template>

<script>
import IconEye from '@/components/service/icons/IconEye.vue';

export default {
  components: { IconEye },
  name: 'PasswordInput',
  props: {
    value: { // v-model의 값을 받을 prop
      type: String,
      default: '',
    },
  },
  data() {
    return {
      type: 'password', // password 또는 text 상태
    };
  },
  methods: {
    toggleType() {
      this.type = this.type === 'password' ? 'text' : 'password';
    },
  },
};
</script>


<style scoped lang="scss">
.search-container {
  display: flex;
  width: 100%;
  position: relative;
}
.form-control {
  padding-right: 43px;
}
.search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 2;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: var(--gray700);
    }
  }
}
</style>
