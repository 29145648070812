<template>
  <div class="page-notice-detail">
    <h2 class="sr-only">공지사항 상세 페이지</h2>
    <h3 class="customer-title">공지사항</h3>
    <div class="notice-detail tbl-detail">
      <div class="notice-header tbl-header">
        <div class="title">
          <span 
              class="label-notice" 
              :class="getNoticeClass(noticeData.noticeType)">
              {{ getNoticeTypeLabel(noticeData.noticeType) }}
          </span>
          <h4 class="txt-ellipsis">
            {{noticeData.title | unescapeXss }}
          </h4>
        </div>
        <div class="info">
          <span>운영자</span>
          <span>{{noticeData.regDt}}</span>
        </div>
      </div>
      <div class="notice-contents tbl-contents">
        <pre>
          <p v-html="noticeData.content"></p>
        </pre>
      </div>
    </div>

    <router-link :to="{ name: 'notice'}">
        <div class="btn-go-list">목록</div>
    </router-link>
  </div>
</template>

<script>
import mainApi from "@/modules/main/service/mainApi.js";

export default {
  name: "noticeDetail",
  props: {
    noticeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      noticeData: {}, // 공지사항 데이터를 담을 객체
    };
  },
  watch: {
    noticeId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fnSelectNoticeInfo(); 
        }
      },
    },
  },
  methods: {
    getNoticeTypeLabel(type) {
      switch (type) {
        case "INFO":
          return "안내";
        case "CHECK":
          return "점검";
        case "ERROR":
          return "장애";
        case "EVENT":
          return "행사";
        default:
          return type;
      }
    },
    getNoticeClass(type) {
      switch (type) {
        case "INFO":
          return "info";
        case "CHECK":
          return "check";
        case "ERROR":
          return "error";
        case "EVENT":
          return "event";
        default:
          return "";
      }
    },
    async fnSelectNoticeInfo() {
      try {
        const response = await mainApi.selectNoticeList({ noticeId: this.noticeId });
        const result = response.data;
        if (result.success && result.data.length > 0) {
          this.noticeData = result.data[0];
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error('Error fetching notice data:', error);
      }
    },
  },
};
</script>



<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as c;


.landing-wrapper pre {
    font-family: inherit;
    font-size: 1em;
}

.page-notice-detail {
    padding-top: 60px;
  @include c.desktop {
    padding-top: 100px;
  }
}

.btn-go-list {
  width: 107px;
  height: 32px;
  margin-top: 20px;
  border-radius: 6px;
  background: c.color(gray600);
  position: relative;
  left: 350%;
  font-weight: 400;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  line-height: 32px;

  @include c.tablet {
    width: 160px;
    height: 52px;
    margin-top: 40px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 52px;
  }
}


.label-notice {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  justify-content: center;
  line-height: 24px;
  @include c.tablet {
    width: 80px;
    height: 34px;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 34px;
  }

  &.event {
    border: 1px solid c.color(primary200);
    background: rgba(233, 233, 254, 0.5);
    color: c.color(primary300);
  }
  &.check {
    border: 1px solid #ff9e9e;
    background: rgba(255, 89, 79, 0.1);
    color: #ff594f;
  }
  &.error {
    border: 1px solid c.color(gray400);
    background: c.color(gray200);
    color: c.color(gray600);
  }
  &.info {
    border: 1px solid c.color(gray300);
    background: #fff;
    color: c.color(gray600);
  }
}

@media (max-width: 350px) {
.btn-go-list  {
    left: 100%;
  }

.landing-wrapper pre {
    width: 250px !important;
    white-space: break-spaces;
}

.landing-wrapper .txt-ellipsis {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: unset;
  }
}

@media (max-width: 375px) {
.btn-go-list  {
    left: 105%;
  }

.landing-wrapper pre {
    width: 300px;
    white-space: break-spaces;
}
  
.landing-wrapper .txt-ellipsis {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: unset;
  }  
}
</style>
