<template>
  <div class="faq-list-container">
    <div class="faq-list-container__title">
      <h3 class="customer-title">자주 묻는 질문</h3> 
    </div>

    <div class="faq-list-container__filters">
      <div class="faq-list-container__filters__tabs">
        <span
          class="faq-list-container__filters__tabs__tab"
          v-for="(faqTypeInfo, idx) in updatedFaqTypeList"
          :key="idx"
          :class="{ active: faqTypeCd === faqTypeInfo.codeVal1 }"
          @click.prevent="fnSetFaqTypeCd(faqTypeInfo.codeVal1)"
        >
          {{ faqTypeInfo.codeName1 }} 
          <!-- ({{ faqTypeInfo.CNT }}) -->
        </span>
      </div>

      <div class="search-box">
        <input
            v-model="keyword"
            type="search"
            placeholder="검색어를 입력해주세요"
            class="search-input base-input"
            @keypress.enter="fnSetSelPage()"
          />
          <button 
            type="button" 
            class="btn-search"
            @click="fnSetSelPage()">
            검색
          </button>
      </div>
    </div>

    <div class="faq-list-container__list-wrapper">
      <div v-for="(contant, idx) in contants" :key="idx" 
        class="faq-list-container__list-wrapper__item">
        <div role="button" class="faq-list-container__list-wrapper__item__title"
          @click="toggleAccordion(idx)" >
          <span class="faq-list-container__list-wrapper__item__title__q">Q.</span>
          <span>{{ contant.titleContent | unescapeXss }}</span>
          <IconArrowDown />
        </div>
        <div class="faq-list-container__list-wrapper__item__contents"  v-show="activeIndex === idx">
          {{ contant.answerContent | unescapeXss }}
        </div>
      </div>
    </div>

    <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></Pagination>
  </div>
</template>
<script>
import Pagination from '../components/MainPagination.vue';
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import mainApi from "@/modules/main/service/mainApi.js";

export default {
  name: "FaqList",
  components: { Pagination, IconArrowDown },

  props: {
    componentsTitle: {
      type: String,
      default: 'FAQ',
    },
  },
  
  data() {
    return {
      keyword: "",
      selected: '',
      searchResults: [], // 검색 결과를 저장
      faqTypeCd: '',
      faqTypeList: [],
      allFaqs: [], // 전체 FAQ 데이터
      contants: [], // 현재 탭 또는 검색 조건에 따른 데이터
      listSize: 10,
      pageNo: 1,
      totCnt: 0,
      offset: 0,
      activeIndex: null,
      isSearchExecuted: false, // 검색이 실행되었는지 여부를 추적
    };
},

computed: {
  updatedFaqTypeList() {
    // searchResults 또는 allFaqs에서 직접 CNT를 계산하기 위해 `isSearchExecuted` 상태 변수를 사용
    const typeCounts = (this.isSearchExecuted ? this.searchResults : this.allFaqs).reduce((acc, item) => {
      acc[item.faqType] = (acc[item.faqType] || 0) + 1;
      return acc;
    }, {});

    return [
      {
        codeVal1: '',
        codeName1: '전체',
        CNT: this.isSearchExecuted ? this.searchResults.length : this.allFaqs.length, // 검색 후 CNT만 반영
      },
      ...this.faqTypeList.map(type => ({
        ...type,
        CNT: typeCounts[type.codeVal1] || 0, // 각 타입별 CNT 값 계산
      })),
    ];
  },
},

  mounted() {
    this.initializeFaqData();
  },

  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },

    async initializeFaqData() {
      try {
        await this.fnSelectFaqType(); // FAQ 타입 로드
        await this.fnSelectFaqList(); // FAQ 데이터 및 CNT 값 동기화
      } catch (error) {
        console.error('FAQ 데이터를 초기화하는 중 오류 발생:', error);
      }
  },
    
  fnSetSelPage() {
    this.pageNo = 1; // 검색 시 페이지 번호 초기화
    this.isSearchExecuted = true; // 검색 실행 상태로 설정
    this.fnSelectFaqList(); // 검색 조건에 따라 데이터 로드
  },

  async fnSetFaqTypeCd(faqTypeCd) {
    this.faqTypeCd = faqTypeCd;
    this.$refs.updatePaging.fnAllDecrease();

    if (this.isSearchExecuted) {
      this.contants = this.searchResults.filter(faq => faq.faqType === faqTypeCd || faqTypeCd === '');
      this.totCnt = this.contants.length;
    } else {
      this.fnSelectFaqList(); 
    }
  },

  async fnSelectFaqList() {
    const params = {
      keyword: this.keyword,
      faqType: this.faqTypeCd,
      pageNo: this.pageNo,
      listSize: this.listSize,
    };

    try {
      const response = await mainApi.selectFaqList(params);
      const result = response.data;
      if (result.success) {
        this.contants = result.data; // 현재 탭에 맞는 데이터
        this.totCnt = result.pageInfo.totCnt;
        this.offset = result.pageInfo.offset;

        // 검색어가 있을 경우 검색 결과를 업데이트
        if (this.keyword) {
          this.searchResults = [...result.data]; // 검색 결과 저장
        } else {
          // 검색어가 없을 경우 전체 데이터 저장
          if (this.allFaqs.length === 0) {
            this.allFaqs = [...result.data]; // 처음 검색할 때 전체 FAQ 데이터를 저장
          }
          this.searchResults = [...this.allFaqs]; // 검색어가 없으면 전체 데이터 사용
        }
      } else {
        alert(this.componentsTitle, result.message);
      }
    } catch (error) {
      console.error('FAQ 리스트를 가져오는 중 오류 발생:', error);
    }
  },

  async fnSelectFaqType() {
    const params = {
      codeTypeCd: 'FAQ_TYPE',
      useYN: 'Y',
    };

    try {
      const response = await mainApi.selectCodeList(params);
      const result = response.data;
      if (result.success) {
        this.faqTypeList = result.data;
      } else {
        alert(this.componentsTitle, result.message);
      }
    } catch (error) {
      console.error('FAQ 타입을 가져오는 중 오류 발생:', error);
    }
  },

  fnSearch(pageNo) {
      this.pageNo = pageNo;
      this.fnSelectFaqList();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as x;

.faq-list-container__filters {
    margin: 40px 0;

    @media (min-width: 300px) {
      margin: 15px 0 40px !important;
      display: flex;
      flex-direction: column;
      align-items: center !important;
    }

    @media (min-width: 375px) {
      margin: 15px 0;
    }

    @media (min-width: 1024px) {
      margin: 15px 0;
    }
    @media (min-width: 1025px) {
      margin: 15px 0;
    }
}

.landing-wrapper h3.customer-title {
  @media (min-width: 375px) {
      padding-bottom: 0px;
    }
}


.faq-list-container {
  margin-top: 38px;
  padding: 60px 0;
  line-height: 1.4;

    @media (min-width: 300px) {
        margin-top: 38px !important;
        padding: 20px 0;
    }

    @media (min-width: 375px) {
        padding: 20px 0;
    }

    @media (min-width: 770px) {
      padding: 60px 0;
    }

    @media (min-width: 1025px) {
      padding: 60px 0;
    }

  &__title {
    color: #111827;
    font-size: 28px;
    font-weight: 700;

    @media (min-width: 375px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 40px 0;

    @media (min-width: 375px) {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 0;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__tabs {
      display: flex;
      padding: 8px 0;

      @media (min-width: 300px) {
          padding: 15px 0;
        }

      &__tab {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 20px;
        padding: 0 26.5px;
        cursor: pointer;

        @media (min-width: 300px) {
          padding: 0 6px;
          font-size: 14px;
        }

        @media (min-width: 375px) {
          padding: 0 10px;
          font-size: 14px;
        }

        @media (min-width: 1024px) {
          padding: 0 16.5px;
          font-size: 20px;
        }

        @media (min-width: 1280px) {
          padding: 0 26.5px;
          font-size: 20px;
        }

        &:last-child {
          padding-right: 0;
        }

        &.active {
          span {
            color: #374151;
          }

          span:first-child {
            font-weight: 700;
          }
        }

        &:not(:last-child) {
          border-right: 1px solid #E5E7EB;
        }

        span {
          color: #6B7280;
        }

        span:last-child {
          font-size: 0.8em;
          font-weight: 500;
        }
      }
    }
  }
}


.faq-list-container__filters__tabs__tab {
  color: #6b7280;
}

span.faq-list-container__filters__tabs__tab.active {
  color: #111827;
  font-weight: 700;
}

.faq-list-container__list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 20px;
    border-radius: 12px;
    border: 1px solid #F3F4F6;
    box-shadow: 0px 4px 16px 0px #53587114;

    @media (min-width: 375px) {
      padding: 8px 12px;
      border-radius: 8px;
    }

    @media (min-width: 1024px) {
      padding: 16px 20px;
      border-radius: 12px;
    }

    &.collapsed {
      .faq-list-container__list-wrapper__item__title :deep svg {
        transform: rotate(0);
      }

      .faq-list-container__list-wrapper__item__contents {
        display: none;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      color: #4B5563;

      &__q {
        padding: 8px 16px;
        background: linear-gradient(308.23deg, #D4E0FF 8.84%, #788FF5 86.03%);
        line-height: 1.4;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        border-radius: 8px;

        @media (min-width: 300px) {
          font-size: 14px;
          padding: 4px 8px;
          border-radius: 4px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
          padding: 4px 8px;
          border-radius: 4px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
          padding: 8px 16px;
          border-radius: 8px;
        }
      }

      span:nth-child(2) {
        padding: 0 40px;
        flex: 1;
        color: #374151;
        font-weight: 700;
        font-size: 20px;

        @media (min-width: 300px) {
          font-size: 14px;
          padding: 0 12px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
          padding: 0 12px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
          padding: 0 40px;
        }
      }

      :deep svg {
        width: 28px;
        height: 28px;
        transform: rotate(180deg);

        @media (min-width: 300px) {
          width: 16px;
          height: 16px;
        }

        @media (min-width: 375px) {
          width: 16px;
          height: 16px;
        }

        @media (min-width: 1024px) {
          width: 28px;
          height: 28px;
        }
      }
    }

    &__contents {
      display: block;
      padding: 20px 40px;
      background-color: #F9FAFB;
      font-size: 18px;
      color: #6B7280;
      font-weight: 500;
      word-break: keep-all;

      @media (min-width: 300px) {
        font-size: 14px;
        padding: 12px;
      }

      @media (min-width: 375px) {
        font-size: 14px;
        padding: 12px;
      }

      @media (min-width: 1024px) {
        font-size: 18px;
        padding: 20px 40px;
      }
    }
  }
}


.search-box {
  @include x.flex(row, null, center);
  .search-input {
    position: relative;
    width: 190px;
    margin-right: 8px;
    background: url(x.$icon + "ico-search.png") no-repeat center;
    background-position-x: calc(100% - 12px);
    @include x.tablet {
      width: 300px;
      margin-right: 12px;
      background-position-x: calc(100% - 16px);
    }
  }

  .btn-search {
    width: 56px;
    height: 35px;
    border-radius: 6px;
    background: x.color(gray600);
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    line-height: 35px;
    @include x.tablet {
      width: 120px;
      height: 44px;
    }
  }
}


@media (max-width: 350px) {
  .search-box {
    @include x.flex(row, null, center);
    .search-input {
      position: relative;
      width: 210px !important;
      margin-right: 8px;
      background: url(x.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include x.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
  }

  .btn-search {
    width: 56px;
    height: 35px;
    border-radius: 6px;
    background: x.color(gray600);
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    line-height: 35px;
    @include x.tablet {
      width: 120px;
      height: 44px;
    }
  }
}
    }

/* 예외 */
@media (max-width: 375px) {
  .search-box {
    @include x.flex(row, null, center);
    .search-input {
      position: relative;
      width: 220px;
      margin-right: 8px;
      background: url(x.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include x.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
    }
  }
}
</style>