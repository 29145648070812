<template>
  <b-modal id="enter-receiver-modal" title="내용 입력" hide-header-close centered size="md">
    <template #modal-title>
      <h5 class="modal-title">수신자 직접입력</h5>
      <div class="d-flex align-items-end justify-content-between w-100">
        <p class="title-desc">* 입력 후 선택 버튼을 누르면 수신자에 추가됩니다.<br/>
          <span>* 최대 30개까지 추가 가능합니다.</span></p>
        <b-button variant="outline-primary" class="btn-svg btn-svg-right ms-auto" @click="fnAddCu">
          <span>수신자 추가</span>
          <IconPlus />
        </b-button>
      </div>
    </template>


    <div class="form-container" style="overflow-x: auto; white-space: nowrap;">
      <!-- 헤더 영역 -->
      <div class="d-flex header-row" style="min-width: max-content;">
        <div
          v-for="header in headerList"
          :key="header"
          class="header-cell text-center"
          style="flex: 1 1 auto; min-width: 215.6px; padding: 8px;"
        >
          {{ header === 'cuid' ? 'APP 로그인ID' : header === 'phone' ? '휴대폰번호' : header }}
        </div>
      </div>

      <!-- 입력 영역 -->
      <div v-for="rowIdx in loopCnt" :key="rowIdx" class="d-flex input-row" style="min-width: max-content;">
        <div
          v-for="header in headerList"
          :key="`${header}_${rowIdx}`"
          class="input-cell p-1"
          style="flex: 1 1 auto; min-width: 120px;"
        >
          <div v-if="recvInfoLst.length >= rowIdx">
            <!-- Phone Input -->
            <input
              v-if="header === 'phone'"
              type="text"
              class="form-control"
              placeholder="휴대폰 번호 입력"
              v-model="recvInfoLst[rowIdx - 1][header]"
              :ref="`${header}_${rowIdx}`"
              @keyup="fnOnlyNumber"
              @keypress="fnOnlyNumber"
              autocomplete="off"
            />

            <!-- CUID Input -->
            <input
              v-else-if="header === 'cuid'"
              type="text"
              class="form-control"
              placeholder="APP 로그인ID 입력"
              v-model="recvInfoLst[rowIdx - 1][header]"
              :ref="`${header}_${rowIdx}`"
            />

            <!-- Other Headers -->
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="데이터 입력"
              v-model="recvInfoLst[rowIdx - 1].mergeData[header]"
              :ref="`${header}_${rowIdx}`"
            />
          </div>

          <!-- Empty Rows -->
          <div v-else>
            <input
              v-if="header === 'phone'"
              type="text"
              class="form-control"
              placeholder="휴대폰 번호 입력"
              :ref="`${header}_${rowIdx}`"
              @keyup="fnOnlyNumber"
              @keypress="fnOnlyNumber"
              autocomplete="off"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="데이터 입력"
              :ref="`${header}_${rowIdx}`"
            />
          </div>
        </div>
      </div>
    </div>


    <template #modal-footer>
      <b-button variant="primary" :disabled="receivers.length === 0" @click="fnCallbackInputData">선택</b-button>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>
    <AlertModal :title="alertTitle" :desc="alertDesc" :callbackFunc="callback" />
  </b-modal>
</template>

<script>
import IconPlus from '@/components/service/icons/IconPlus.vue'
import AlertModal from '@/components/service/modal/AlertModal.vue'

export default {
  components: { IconPlus, AlertModal, },
  name: "EnterReceiverModal",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    directInputOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
    requiredCuPhone: {
      type: Boolean,
      require: true,
      default: true,
    },
    requiredCuid: {
      type: Boolean,
      require: true,
      default: true,
    },
    contsVarNms : {
      type: Array,
      require: true
    },
    recvInfoLst : {
      type: Array,
      require: false,
      default: function() {
        return [];
      }
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return '수신자 직접입력';
      }
    },
  },
  data() {
    return {
      loopCnt: 3,
      headerList: [],
      receivers: [
        // { phone: '', name: '' },
        { phone: '' },
      ],
      alertTitle: '주소록 검색',
      alertDesc: '',
      callback: () => { },
    }
  },
  watch: {
    directInputOpen(val){
      if(val){
        if(this.recvInfoLst.length > this.loopCnt) this.loopCnt = this.recvInfoLst.length;

        if(this.recvInfoLst.length > 0){
          for(let i = 0 ; i < this.recvInfoLst.length ; i++){
            this.recvInfoLst[i].phone = this.$gfnCommonUtils.hpNumberAddDash(this.recvInfoLst[i].phone);
          }
        }
      }else{
        if(this.recvInfoLst.length > 0){
          for(let i = 0 ; i < this.recvInfoLst.length ; i++){
            this.recvInfoLst[i].phone = this.$gfnCommonUtils.hpNumberRemoveDash(this.recvInfoLst[i].phone);
          }
        }
      }
    },
    contsVarNms: {
      handler: function(){
        let headerList = [];
        if(this.requiredCuid) headerList.push('cuid');
        if(this.requiredCuPhone) headerList.push('phone');
        headerList = headerList.concat(this.contsVarNms);
        this.headerList = headerList.filter((item, pos) => headerList.indexOf(item) === pos);
      }
    }
  },
  methods: {
    fnAddCu(){
      ++this.loopCnt;
    },
    //빈값확인
    fnIsEmpty(str){
      if(str) return false;
      else return true
    },
    //빈오브젝트확인
    fnIsEmptyObj(obj){
      if(typeof obj === 'undefined') return true;
      if(Object.keys(obj).length === 0) return true;
      return false;
    },
    fnOnlyNumber($event) {
      // var keyCode = $event.which;
      // if(keyCode != "8" && keyCode != "46") {
      //   if (!/\d/.test($event.key)){
      //     return $event.preventDefault();
      //   }
      // if((event.keyCode >= 48 && event.keyCode <= 57 ) || (event.keyCode >= 96 && event.keyCode <= 105 )){
      //   event.target.value = this.$gfnCommonUtils.hpNumberAddDash(event.target.value);
      // }
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 45 && keyCode !== 8 && (keyCode < 94 || keyCode > 105) && keyCode !== 46 && (keyCode < 37 || keyCode > 40) ) {
 					$event.preventDefault();
 			}
      $event.target.value = this.$gfnCommonUtils.hpNumberAddDash($event.target.value);
    },
    //직접입력 팝업 내 선택 버튼 클릭시
    fnCallbackInputData(){
      const vm = this;
      let recvInfoLst = [];
      // var recvInfo = {};
      let hasEmptyKey = false;
      let hasEmptyProp = false;
      const alertKeyStr = (this.requiredCuid ? 'APP 로그인' : '') + (this.requiredCuid && this.requiredCuPhone ? ', ' : '') + (this.requiredCuPhone ? '휴대폰번호' : '');
      for(let idx=1; idx<=this.loopCnt; idx++){
        let recvInfo = {phone:'',cuid:'',mergeData:{}};
        hasEmptyProp = false;
        //데이터 담기
        if(!(vm.requiredCuid && !vm.fnIsEmpty(vm.$refs['cuid_'+idx][0].value))
          && !(vm.requiredCuPhone && !vm.fnIsEmpty(vm.$refs['phone_'+idx][0].value))){
          continue;
        }
        if(vm.requiredCuid){
          if(vm.fnIsEmpty(vm.$refs['cuid_'+idx][0].value)){
            hasEmptyKey = true;
            break;
          } else {
            recvInfo.cuid = vm.$refs['cuid_'+idx][0].value;
          }
        } else {
          delete recvInfo.cuid;
        }
        if(vm.requiredCuPhone){
          if(vm.fnIsEmpty(vm.$refs['phone_'+idx][0].value)){
            hasEmptyKey = true;
            break;
          } else {
            recvInfo.phone = this.$gfnCommonUtils.hpNumberRemoveDash(vm.$refs['phone_'+idx][0].value);
          }
        } else {
          delete recvInfo.phone;
        }
        vm.contsVarNms.forEach(function(varNm){
          if(vm.fnIsEmpty(vm.$refs[varNm+'_'+idx][0].value)){
            hasEmptyProp = true;
            return false;
          } else {
            recvInfo.mergeData[varNm] = vm.$refs[varNm+'_'+idx][0].value
          }
        });
        if(hasEmptyProp) break;
        recvInfoLst.push(recvInfo);
      }
      
      if(hasEmptyKey){
        confirm.fnAlert(this.componentsTitle, alertKeyStr+'는(은) 필수입니다.');
        return;
      }
      if(hasEmptyProp){
        confirm.fnAlert(this.componentsTitle, alertKeyStr+'를(을) 입력한 행은 빈값이 존재하면 안됩니다.');
        return;
      }
      if(recvInfoLst.length == 0){
        confirm.fnAlert(this.componentsTitle, '수신자 정보를 입력해주세요.');
        return;
      }
      
      
      this.$parent.fnCallbackRecvInfoLst(recvInfoLst);
      this.fnClose();
    },
    addReceiver() {
      if (this.receivers.length < 30) {  // 최대 30개 제한
        // this.receivers.push({ phone: '', name: '' });
        this.receivers.push({ phone: '' });
      }
    },
    closeModal() {
      this.loopCnt=3;
      this.$bvModal.hide('enter-receiver-modal');
    },
    fnAddCu(){
      ++this.loopCnt;
    },
    onSubmit() {
      this.$bvModal.show('alert-modal');
    }
  }
};
</script>

<style scoped lang="scss">
.form-row {
  display: flex;
  & > * {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  label + label {
    margin-right: 0;
    margin-left: 10px;
  }
  .form-control + .form-control {
    margin-right: 0;
    margin-left: 10px;
  }
  &.input-row + .input-row {
    margin-right: 0;
    margin-left: 10px;
  }
}
</style>
