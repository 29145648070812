<template>
  <div class="qna-container">
    <div class="qna-container__title">
     <h3 class="customer-title">1:1 문의</h3> 
    </div>

    <div class="qna-container__form-wrapper">
      <LoginForm>
        <LoginFormRadioGroupField label="문의 유형" required >
          <div v-for="(inqueiryType, idx) in inqueiryTypeList" class="login-form__radio">
                <input :key="idx" type="radio" name="inqueiryType1" :value="inqueiryType.codeVal1"
                  :id="'inqueiryType1_'+inqueiryType.codeVal1" v-model="inputData.questType">
                <label :key="idx+'_sub'" :for="'inqueiryType1_'+inqueiryType.codeVal1"
                  class="inqueiry_label">{{inqueiryType.codeName1}}</label>
          </div>
        </LoginFormRadioGroupField>

        <LoginFormTextField label="제목" placeholder="제목을 입력해주세요" items="flex-start" v-model="inputData.title" required />

        <div class="qna-container__form-wrapper__textarea">
          <LoginFormTextField label="문의 내용" type="textarea" 
          placeholder="문의 내용을 작성" items="flex-start" v-model="inputData.content" required />
          
          <div class="qna-container__form-wrapper__textarea__btn-file">
            <button type="button" @click="fnAttachCorpImg(0)">파일 첨부</button>
          </div>

          <div class="login_file_field" v-show="attachFileNm !== ''">
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.26463 4.8V19.2H17.7354V9.525H13.9855C13.4982 9.525 13.1032 9.12206 13.1032 8.625V4.8H6.26463ZM14.8678 6.07279L16.4876 7.725H14.8678V6.07279ZM4.95224 3.46131C5.24181 3.16594 5.63454 3 6.04405 3H13.9855C14.2195 3 14.444 3.09482 14.6094 3.2636L19.2416 7.9886C19.407 8.15739 19.5 8.3863 19.5 8.625V19.425C19.5 19.6318 19.4601 19.8366 19.3825 20.0277C19.3049 20.2188 19.1911 20.3924 19.0478 20.5387C18.9044 20.685 18.7342 20.801 18.5468 20.8801C18.3595 20.9593 18.1587 21 17.9559 21H6.04405C5.63455 21 5.24181 20.8341 4.95224 20.5387C4.66268 20.2433 4.5 19.8427 4.5 19.425V4.575C4.5 4.15728 4.66268 3.75668 4.95224 3.46131ZM8.47106 12.675C8.47106 12.1779 8.86609 11.775 9.35338 11.775H14.6473C15.1346 11.775 15.5296 12.1779 15.5296 12.675C15.5296 13.1721 15.1346 13.575 14.6473 13.575H9.35338C8.86609 13.575 8.47106 13.1721 8.47106 12.675ZM8.47106 15.375C8.47106 14.8779 8.86609 14.475 9.35338 14.475H14.6473C15.1346 14.475 15.5296 14.8779 15.5296 15.375C15.5296 15.8721 15.1346 16.275 14.6473 16.275H9.35338C8.86609 16.275 8.47106 15.8721 8.47106 15.375Z" fill="currentColor"/>
              </svg>
                <input
                  type="text"
                  class="login-form__field__input__file-name"
                  v-model="attachFileNm"
                  disabled
                />
                <input
                  type="file"
                  id="attachCorpImg"
                  @change="fnFileChange(0)"
                  ref="attachFile"
                  style="display:none;"
                />
              </div>
              <button @click="fnFileDel(1)" class="file_del">삭제</button>
            </div>

          <div class="qna-container__form-wrapper__textarea__file-desc">
            <div>첨부 가능 : jpg, jpeg, gif, png, tiff, hwp, pdf, xls, xlsx, ppt, pptx, doc, docx</div>
            <div style="font-weight: 700;">
              (<strong> {{ uploadedFileSize }} MB</strong> / 5 MB)
            </div>
          </div>
        </div>

        <LoginFormTextField label="회사명" placeholder="회사명을 입력해주세요" 
          items="flex-start" v-model="inputData.corpName" />
        <LoginFormTextField label="성함" placeholder="성함을 입력해주세요" 
          items="flex-start" v-model="inputData.name" required />

          <div class="d-flex align-items-center qna-container__form-wrapper__email">
            <LoginFormTextField style="flex: 1;" label="이메일" 
              placeholder="이메일 주소를 입력해주세요"
              items="flex-start" 
              v-model="localEmail" required 
            />

            <span>@</span>

            <div v-if="isCustomDomain">
              <LoginFormTextField input
                type="text"
                class="custom-domain-input"
                placeholder="도메인 입력"
                v-model="selectedDomain"
                @input="updateEmail"
              />
            </div>

          <div v-else>
            <b-dropdown variant="primary" v-model="selectedDomain" @change="updateEmail">
              <template #button-content>
                <span>{{ selectedDomain || "선택" }}</span>
                <IconArrowDown />
              </template>
              <b-dropdown-text @click="setEmailDomain('')">
                <span>직접 입력</span>
              </b-dropdown-text>
              <b-dropdown-text @click="setEmailDomain('gmail.com')">
                <span>gmail.com</span>
              </b-dropdown-text>
              <b-dropdown-text @click="setEmailDomain('naver.com')">
                <span>naver.com</span>
              </b-dropdown-text>
            </b-dropdown>
          </div>
        </div>

        <LoginFormTextField
          input
          @input="(value) => fnCorrectNumberInput(value)"
          type="text" 
          id="hpNumber"
          label="연락처"
          placeholder="연락처를 입력해주세요"
          items="flex-start"
          v-model="inputData.hpNumber"
          required
        />
      </LoginForm>
    </div>

    <div class="qna-container__terms">
      <LoginFormSingleCheckboxField class="checkbox-terms" v-model="agree">
        <template #label>
          <div>
            <strong style="color: #6D6EFA">[필수]</strong>
            <span>&nbsp;개인정보 수집, 이용에 동의합니다.</span>
          </div>
        </template>
      </LoginFormSingleCheckboxField>
    </div>

    <button class="btn-post" @click.prevent="fnRegisterInquiry">문의 등록</button>

    <AlertModal :title="alertTitle" :desc="alertDesc" />
    <ConfirmModal :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />

  </div>
</template>

<script>
import LoginForm from '../../../components/landing/login/LoginForm.vue'
import LoginFormRadioGroupField from '../../../components/landing/login/LoginFormRadioGroupField.vue'
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue'
import LoginFormSingleCheckboxField from '../../../components/landing/login/LoginFormSingleCheckboxField.vue'
import IconArrowDown from '../../../components/service/icons/IconArrowDown.vue'
import AlertModal from '../../../components/service/modal/AlertModal.vue'
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue';
import mainApi from "@/modules/main/service/mainApi.js";


export default {
  name: "InquiryForm",
  props: {
    componentsTitle: {
      type: String,
      require: false,
    },
  },

  components: {
    LoginForm,
    LoginFormRadioGroupField,
    LoginFormTextField,
    LoginFormSingleCheckboxField,
    IconArrowDown,
    AlertModal,
    ConfirmModal,
  },

  data() {
    return {
      inqueiryTypeList: [],
      agree: false,
      isCustomDomain: false, // 직접 입력 활성화 여부
      localEmail: '', // 사용자가 입력한 이메일 ID
      selectedDomain: '', // 선택된 이메일 도메인
      inputData: {
        questType: '',
        title: '',
        content: '',
        name: '',
        corpName: '',
        hpNumber: '',
        email: '',
        attachFileNm: ''
    },
      confirmTitle: '',
      confirmDesc: '',
      alertTitle: '',
      alertDesc: '',
      title: '',
      desc: '',
      callback: () => { }, // 기본 함수로 초기화
      attachFileNm: "",
      uploadedFileName: "파일선택",
      uploadedFileSize: "0",
      maxFileSize: 5, // MB 단위
      hpNumber : '',
      agreementChecked: false, // 체크 여부를 관리하는 데이터 속성
  }
},
  watch: {
    'inputData.hpNumber'() {
      return this.$gfnCommonUtils.hpNumberAddDash(this.inputData.hpNumber);
    },
    agreementChecked(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.agreementChecked = false;
      }
    },
    localEmail: "updateEmail",
    selectedDomain: "updateEmail"
  },

  mounted() {
    this.fnSelectQnaType();
  },

  methods: {
    // 위에서 선택한 도메인 가져오기기
    setEmailDomain(domain) {
        if (domain === '') {
          // 직접 입력 활성화
          this.isCustomDomain = true;
          this.selectedDomain = '';
        } else {
          this.isCustomDomain = false;
          this.selectedDomain = domain;
        }
        this.updateEmail();
      },
    updateEmail() {
        if (this.localEmail && this.selectedDomain) {
          this.inputData.email = `${this.localEmail}@${this.selectedDomain}`;
        } else {
          this.inputData.email = '';
        }
      },

    showConfirm(message, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.confirmTitle = '일대일 문의'
      this.confirmDesc = message
      this.callback = method
      this.$bvModal.show('confirm-modal');
    },

    showAlert(msg) {
      this.alertTitle = '일대일 문의'
      this.alertDesc = msg
      this.$bvModal.show('alert-modal');
    },

    fnIsValid() {
      if (!this.inputData.questType) {
      this.showAlert("문의 유형은 필수사항입니다.");
      return false;
    }
      if (!this.inputData.title) {
      this.showAlert("제목은 필수사항입니다.");
      return false;
    }
      if (!this.inputData.content) {
      this.showAlert("문의 내용은 필수사항입니다.");
      return false;
    }
    if (!this.inputData.email) {
      this.showAlert("이메일은 필수사항입니다.");
      return false;
    }
      if (!this.inputData.name) {
      this.showAlert("이름은 필수사항입니다.");
      return false;
    }
    if (!this.inputData.hpNumber) {
      this.showAlert("연락처는 필수사항입니다.");
      return false;
    }
    if (this.inputData.hpNumber.length > 20) {
      this.showAlert("연락처를 정확히 입력해주세요");
			return false;
		}
      if (!this.inputData.email) {
      this.showAlert("이메일은 필수사항입니다.");
      return false;
    }
    if (!this.agree) {
      this.showAlert("개인정보 수집, 이용 미동의 시 서비스를 이용하실 수 없습니다.");
      return false;
    }
    return true;
  },

    // 첨부파일 입력
    fnAttachCorpImg(num) {
      if (num == 0) {
        this.$refs.attachFile.click();
      }
    },

    fnCorrectNumberInput(value) {
      this.inputData.hpNumber = this.$gfnCommonUtils.hpNumberAddDash(value);
    },

    fnRegisterInquiry() {
    // 유효성 검사 실패시 return
    if (!this.fnIsValid()) {
      return; 
    }

    // 유효성 검사를 통과하면 실행
      this.fnProcRegisterInquiry(); 
    },

    async fnProcRegisterInquiry() {

      // 연락처의 하이픈 제거
      this.inputData.hpNumber = this.$gfnCommonUtils.hpNumberRemoveDash(this.inputData.hpNumber);
      
      const fd = new FormData();
      fd.append('questType', this.inputData.questType);
      fd.append('title', this.inputData.title);
      fd.append('content', this.inputData.content);
      fd.append('name', this.inputData.name);
      fd.append('corpName', this.inputData.corpName);
      fd.append('hpNumber', this.inputData.hpNumber);
      fd.append('email', this.inputData.email);
      fd.append('userId', this.inputData.email);
      if (this.$refs.attachFile.files[0]) {
        fd.append('attachFile', this.$refs.attachFile.files[0]);
      }
   
      
      try {
        const response = await mainApi.insertQuestBoard(fd);
        const result = response.data;

        if (result.success) {
          this.showAlert("작성하신 문의가 등록 완료되었습니다. 문의하신 내용의 답변은 이메일로 발송됩니다.");
        } else {
          this.showConfirm(result.message);
        }
      } catch (error) {
        this.showConfirm(error.message);
      }
    },

    fnSelectQnaType() {
      const params = {
        codeTypeCd: "QNA_TYPE",
        useYN: "Y"
      };

      mainApi.selectCodeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.inqueiryTypeList = Object.assign({}, result.data);
        } else {
          this.showAlert(this.componentsTitle, result.message);
        }
      });
    },
    fnRestData() {
      // 데이터 초기화
      Object.assign(this.$data, this.$options.data());
    },

    fnFileChange(num) {
  if (num === 0) {
    const file = this.$refs.attachFile.files[0];
    if (file) {
      const attachFileNm = file.name;
      const ext = attachFileNm.slice(attachFileNm.lastIndexOf(".") + 1).toLowerCase();
      const allowedExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tiff', 'hwp', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx'];
      const maxFileSizeMB = 5; // 5 MB로 설정

      // 파일 크기 검사
      if (file.size / (1024 * 1024) > maxFileSizeMB) {
        this.showAlert("첨부할 수 있는 파일 용량은 5MB를 넘을 수 없습니다.");
        this.$refs.attachFile.value = null; // 파일 선택 창 초기화
        this.attachFileNm = ""; // 파일 이름 초기화
        this.uploadedFileSize = "0"; // 파일 크기 초기화
        return; // 이후 코드 실행 중단
      }

      // 파일 확장자 검사
      if (!(allowedExtensions.includes(ext))) {
        this.showAlert("첨부 가능 파일 형식은 jpg, jpeg, gif, png, tiff, hwp, pdf, xls, xlsx, ppt, pptx, doc, docx입니다.");
        this.$refs.attachFile.value = null; // 파일 선택 창 초기화
        this.attachFileNm = ""; // 파일 이름 초기화
        this.uploadedFileSize = "0"; // 파일 크기 초기화
        return; // 이후 코드 실행 중단
      }

      // 유효한 파일일 경우 데이터 업데이트
      this.attachFileNm = attachFileNm;
      this.uploadedFileSize = (file.size / (1024 * 1024)).toFixed(2); // 파일 크기 업데이트
      }
    }
  },

    fnFileDel(num) {
      if (num == 1) {
        this.attachFileNm = "";
        this.fileDel = true;
        this.uploadedFileName = "파일선택";
        this.uploadedFileSize = "0";
        this.$refs.attachFile.value = null;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-form__radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;

    ~label {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 0;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      font-weight: 400;

      @media (min-width: 300px) {
        font-size: 12px;
      }

      @media (min-width: 375px) {
        font-size: 12px;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
      }

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border: 1.25px solid #9CA3AF;
        border-radius: 999px;
        box-sizing: border-box;
        content: '';
      }
    }

    &:checked~label {
      position: relative;

      &::before {
        border: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('#{$icon}icon-radio-on.svg');
      }
    }
  }
}
.login-form__field {
  position: relative;
  &__input {
    position: relative;
    flex: 1;
    width: 100%;
    
    input {
      display: none;
    }
}
}

.login_file_field {
    width: 81%;
    margin-left: 19%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background-color: #F3F4F6;
    border-radius: 8px;
    padding: 10px 8px;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;

    .login-form__field__input {
      display: none;
    }

    .login-form__field__input__file-name {
      margin-top: 4px;
      margin-left: 10px;
      font-weight: 500;
      }

  .file_del {
      margin-right: 10px;
    }

    @media (max-width: 1400px) {
      width: 79%;
      margin-left: 21%;
    }

    
    @media (max-width: 1174px) {
      width: 77%;
      margin-left: 23%;
    }

    @media (max-width: 1020px) {
      width: 100%;
      margin-left: 0%;
    }

    @media (max-width: 344px) {
      flex-direction: column;
    }
}

  .qna-container {
    margin-top: 38px;
    padding: 60px 0;
    line-height: 1.4;

    @media (min-width: 300px) {
        padding: 20px 0;
    }

    @media (min-width: 375px) {
        padding: 20px 0;
    }

    @media (min-width: 770px) {
      padding: 60px 0;
    }

    @media (min-width: 1025px) {
      padding: 60px 0;
    }

  &__title {
    color: #111827;
    font-size: 28px;
    font-weight: 700;

    @media (min-width: 300px) {
      font-size: 20px;
    }

    @media (min-width: 375px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  &__form-wrapper {
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;

    @media (min-width: 300px) {
      padding: 32px 20px;
      margin: 20px 0;
      border-radius: 12px;
    }

    @media (min-width: 375px) {
      padding: 32px 20px;
      margin: 20px 0;
      border-radius: 12px;
    }

    @media (min-width: 1024px) {
      padding: 48px 40px;
      margin: 40px 0;
      border-radius: 20px;
    }

    @media (min-width: 1280px) {
      padding: 60px;
      margin: 40px 0 80px;
      border-radius: 20px;
    }

    &__textarea {
      &__btn-file {
        position: relative;

        button {
          position: absolute;
          right: 16px;
          bottom: 16px;
          background-color: #4B5563;
          color: #fff;
          font-size: 14px;
          line-height: 1.8;
          font-weight: 400;
          z-index: 1;

          @media (min-width: 300px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }

          @media (min-width: 375px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }

          @media (min-width: 1024px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }
        }
      }

      &__file-desc {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 8px;
        font-size: 12px;
        color: #9CA3AF;

        strong {
          color: #374151;
        }

        @media (min-width: 300px) {
          padding-left: 0;
          flex-direction: column;
        }

        @media (min-width: 375px) {
          padding-left: 0;
          flex-direction: column;
        }

        @media (min-width: 1024px) {
          padding-left: 222px;
          flex-direction: row;
        }
      }

      :deep textarea {
        height: 184px;
        margin-bottom: 4px;

        @media (min-width: 300px) {
          height: 144px;
        }

        @media (min-width: 375px) {
          height: 144px;
        }

        @media (min-width: 1024px) {
          height: 184px;
        }
      }

      :deep .login-form__field.file {
        .login-form__field__label {
          opacity: 0;

          @media (min-width: 300px) {
            display: none;
          }

          @media (min-width: 375px) {
            display: none;
          }

          @media (min-width: 1024px) {
            display: initial;
          }
        }
      }
    }

    &__email {
      display: flex;
      align-items: center;
      font-size: 18px;

      >span {
        margin: 0;
        padding: 0 12px;
        font-weight: 500;
        color: #6B7280;

        @media (min-width: 300px) {
          margin-top: 32px;
        }

        @media (min-width: 375px) {
          margin-top: 32px;
        }

        @media (min-width: 1024px) {
          margin: 0;
        }
      }

      :deep .b-dropdown {
        margin: 0;
        background-color: #ffffff;
        border: 1px solid #D1D5DB;
        border-radius: 8px;

        @media (min-width: 300px) {
          margin-top: 30px;
        }

        @media (min-width: 375px) {
          margin-top: 32px;
        }

        @media (min-width: 1024px) {
          margin: 0;
        }
      }

      :deep .b-dropdown .dropdown-toggle,
      :deep .b-dropdown .dropdown-toggle:hover {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 220px !important;
        padding: 9.5px 16px !important;
        box-sizing: border-box !important;

        @media (min-width: 300px) {
          width: 70px !important;
        }

        @media (min-width: 375px) {
          width: 100px !important;
        }

        @media (min-width: 1024px) {
          width: 220px !important;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.8;
          color: #6B7280 !important;

          @media (min-width: 300px) {
            font-size: 12px;
          }

          @media (min-width: 375px) {
            font-size: 12px;
          }

          @media (min-width: 1024px) {
            font-size: 14px;
          }
        }

        &::after {
          content: none;
        }
      }

      :deep .b-dropdown .dropdown-menu {
        margin-top: 4px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #D1D5DB;
        box-shadow: 0 6px 12px 0px #62688A3D;

        li {
          padding: 12px 16px;
          border-bottom: 1px solid #D1D5DB;

          .b-dropdown-text span {
            font-size: 14px;
          }
        }
      }
    }

    :deep .login-form {
      --gap: 60px !important;

      @media (min-width: 300px) {
        --gap: 40px !important;
      }

      @media (min-width: 375px) {
        --gap: 40px !important;
      }

      @media (min-width: 1024px) {
        --gap: 60px !important;
      }
    }

    :deep .login-form__field {
      @media (min-width: 300px) {
        gap: 12px;
      }

      @media (min-width: 375px) {
        gap: 12px;
      }

      @media (min-width: 1024px) {
        gap: 120px;
      }
    }

    :deep .login-form__field__label {
      width: 102px;
      min-width: 102px;

      label {
        font-size: 20px;
        font-weight: 500;
        color: #111827;

        @media (min-width: 300px) {
          font-size: 14px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }
  }

  &__terms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
    border-bottom: 1px solid #D1D5DB;
    
    @media (min-width: 300px) {
      margin-bottom: 40px;
    }

    @media (min-width: 375px) {
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 80px;
    }

    >button {
      padding: 12px;
      background-color: #E9E9FE;
      border-radius: 8px;
      line-height: 1.43;
      font-size: 14px;
      color: #6D6EFA;

      @media (min-width: 300px) {
        padding: 3.5px 11px;
        border-radius: 6px;
        line-height: 1.8;
        font-weight: 400;
      }

      @media (min-width: 375px) {
        padding: 3.5px 11px;
        border-radius: 6px;
        line-height: 1.8;
        font-weight: 400;
      }

      @media (min-width: 1024px) {
        padding: 12px;
        border-radius: 8px;
        line-height: 1.43;
        font-weight: 700;
      }
    }
  }
}

.checkbox-terms {
  @media (min-width: 300px) {
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  @media (min-width: 375px) {
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center !important;
  }

  :deep label {
    font-size: 24px;

    @media (min-width: 300px) {
      font-size: 13px;
    }

    @media (min-width: 375px) {
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }

    span {
      color: #111827;
    }
  }
}

.btn-post {
  display: block;
  margin: 0 auto;
  padding: 0 91px;
  width: fit-content;
  height: 52px;
  background-color: #6D6EFA;
  border-radius: 12px;
  border: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 300px) {
    padding: 0;
    width: 100%;
    font-size: 14px;
  }

  @media (min-width: 375px) {
    padding: 0;
    width: 100%;
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    padding: 0 91px;
    width: fit-content;
    font-size: 16px;
  }
}
</style>
