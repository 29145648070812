<template>
  <b-modal v-model="imgMngOpen" scrollable id="select-image-modal" hide-header-close centered size="lg" class="select-image-modal">
    <template #modal-title>
      <h5 class="modal-title">통합 이미지 관리</h5>
      <p class="title-desc">* 광고성메시지 수신거부번호는 내용 하단에 포함됩니다. 또한 광고 표기는 제목 또는 내용에 포함되어 있어야 합니다.
        <br />
        <span>* 이미지는 최대 3개까지 추가 가능합니다.</span>
      </p>
    </template>

    <div class="d-flex align-items-center mb-1 pb-3">
      <label class="mr-auto mb-0 f-body1 c-gray900">업로드한 이미지</label>
      <b-button variant="outline-primary" class="btn-svg btn-svg-right mr-1" v-b-modal.upload-image-modal>
        <span>이미지 추가</span>
        <IconPlus />
      </b-button>
      <b-button @click="fnDeleteImage" variant="outline-secondary" class="ml-2">삭제</b-button>
    </div>

    <div class="table-responsive sticky-header">
      <table class="table table-middle">
        <colgroup>
          <col width="5%">
          <col width="15%">
          <col width="15%">
          <col width="15%">
          <col width="20%">
          <col width="15%">
          <col width="15%">
        </colgroup>
        <thead>
          <tr>
            <th scope="col" class="text-center">
              <b-form-checkbox id="selectAll" name="selectAll" v-model="listAllChecked" @change="fnListChkAll"
                size="lg"></b-form-checkbox>
            </th>
            <th scope="col">File ID</th>
            <th scope="col">파일명</th>
            <th v-if="useCh == 'FRIENDTALK'" scope="col">와이드여부</th>
            <th scope="col">사용 채널</th>
            <th scope="col">업로드 일시</th>
            <th scope="col" class="text-center">미리보기</th>
            <th scope="col" class="text-center">이미지 선택</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(contant, idx) in contants" :key="idx">
            <td class="text-center">
              <b-form-checkbox :id="'checkbox-' + idx" :value="contant.fileId" v-model="listChkBox" size="lg"></b-form-checkbox>
            </td>
            <td>{{ contant.fileId }}</td>
            <td>{{ contant.originFileName }}</td>
            <td v-if="useCh == 'FRIENDTALK'">{{ contant.wideImgYn }}</td>
            <td>{{ contant.useChInfo }}</td>
            <td>{{ contant.regDt }}</td>
            <td class="text-center">
              <button @click="fnOpenImagePreviewPopUp(contant.chImgUrl)" type="button" class="btn btn-icon p-0">
                <IconSearch />
              </button>
            </td>
            <td class="text-center">
              <b-button @click="fnSelectImage(idx)" variant="outline-secondary" size="sm">선택</b-button>
            </td>
          </tr>
          <tr v-if="contants.length == 0">
            <td class="text-center" colspan="7">업로드 한 이미지가 없습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ul class="info">
      <li>• 이미지는 .jpg, .jpeg, .png(MMS 제외) 형식만 지원합니다</li>
      <li>• 이미지 크기는 PUSH(1MB), RCS(1MB), 친구톡(Normal-500KB, Wide-2MB), MMS(300KB) 이하여야합니다.</li>
      <li>• 이미지는 가로 길이는 최소 500px, 세로 길이는 최소 250px 이상이어야합니다.</li>
      <li>• 이미지의 가로:세로 비율은 2:1 이상, 3:4 이하여야 합니다.</li>
    </ul>

    <Pagination @fnClick="fnClick" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging" />

    <template #modal-footer>
      <b-button @click="fnSelectImages(listChkBox)" variant="primary">선택</b-button>
      <b-button @click="fnClose" variant="outline-primary">닫기</b-button>
    </template>

    <!-- <ConfirmModal title="SMS 발송 내용" desc="광고성 메시지 수신거부번호를 입력해주세요." :onSubmit="closeDeleteModal" /> -->
    <AlertModal :title="alertTitle" :desc="alertDesc" />
    <ConfirmModal :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />

    <UploadImageModal @upload-complete="fnSearch" />
    <ImagePreview :imgPreviewOpen.sync="imgPreviewOpen" ref="imgPreview" />
  </b-modal>
</template>

<script>
import IconPlus from '@/components/service/icons/IconPlus.vue'
import IconSearch from '@/components/service/icons/IconSearch.vue'
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue'
import UploadImageModal from '@/modules/message/components/modal/UploadImageModal.vue'
import CommonUtilApi from "@/modules/commonUtil/service/commonUtilApi.js";
import AlertModal from '@/components/service/modal/AlertModal.vue'
import ImagePreview from "@/modules/commonUtil/components/bp-imagePreview.vue";
import Pagination from '@/components/service/Pagination.vue';

export default {
  components: { IconPlus, IconSearch, UploadImageModal, ConfirmModal, AlertModal, ImagePreview, Pagination },
  name: "SelectImageModal",
  props: {
    useCh: String,
    imgMngOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function () {
        return '통합 이미지 관리';
      }
    },
  },
  data() {
    return {
      contants: [],
      imgPreviewOpen: false,
      imgMngOpen: false,
      selectedIds: [],
      listChkBox: [],
      listAllChecked: {
        type: Boolean,
        default: false
      },
      totCnt: 0,  //전체 리스트 수
      listSize: 5,  // select 박스 value (출력 갯수 이벤트)
      pageNo: 1,  // 현재 페이징 위치
      alertTitle: '',
      alertDesc: '',
      confirmTitle: '',
      confirmDesc: '',
      callback: () => { } // 기본 함수로 초기화
    }
  },
  watch: {
    imgMngOpen(newVal) {
      if (newVal) {
        this.fnSearch(); // 모달이 열릴 때 실행
      }
    }
  },
  mounted() {
    this.fnSearch();
  },
  methods: {
    showConfirm(message, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.confirmTitle = this.componentsTitle
      this.confirmDesc = message
      this.callback = method
      this.$bvModal.show('confirm-modal');

      // this.$refs.confirmModal.openModal(this.componentsTitle, message, method);
    },
    showAlert(msg) {
      this.alertTitle = this.componentsTitle
      this.alertDesc = msg
      this.$bvModal.show('alert-modal');
    },
    fnSetRtnParams(params) {
      this.rtnParams = Object.assign({}, params);
    },
    // 이미지 선택
    fnSelectImage(idx) {
      let rtnData = this.contants[idx];
      this.$emit('img-callback', rtnData);
      this.fnClose();
    },
    // 이미지 다중 선택
    fnSelectImages(selectedIds) {
      const selectedImages = this.contants.filter(contant => selectedIds.includes(contant.fileId));
      this.$emit('img-callback', selectedImages);
      this.fnClose();
    },
    // 조회
    async fnSearch() {

      const vm = this;
      const params = {
        'useChInfo': this.useCh,
        'pageNo': this.pageNo,
        'listSize': this.listSize
      };

      await CommonUtilApi.selectImageList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
        }

        this.contants.forEach(function (obj) {
          obj['useChInfo'] = vm.fnSetUseChInfo(obj.useChInfo);
        });
      });
    },
    //사용채널 json -> string, code -> codeName
    fnSetUseChInfo(jsonStr) {
      let useChStr = '';
      const useChArray = JSON.parse(jsonStr);
      const mappingCdNm = {
        'RCS': 'RCS',
        'FRIENDTALK': '친구톡',
        'MMS': 'MMS'
      };

      try {
        useChArray.forEach(function (key) {
          if (mappingCdNm[key]) useChStr += (useChStr == '' ? '' : ', ') + mappingCdNm[key];
        });
      } catch (error) {
        useChStr = '';
      }

      return useChStr;
    },
    //파일업로드팝업 닫기
    fnClose() {
      this.listChkBox = [];
      this.$bvModal.hide('select-image-modal');
    },
    //이미지 미리보기 버튼 클릭시
    fnOpenImagePreviewPopUp(imgUrl) {
      this.$refs.imgPreview.fnOpenImg(imgUrl);
      this.imgPreviewOpen = !this.imgPreviewOpen;
    },
    //리스트 전체 체크박스
    fnListChkAll() {
      if (this.listAllChecked) {
        this.listChkBox = this.contants.map(contant => contant.fileId);
      } else {
        this.listChkBox = []
      }
    },
    // page 선택
    fnClick(pageNo) {
      this.pageNo = pageNo;
      this.fnSearch();
    },
    //이미지 삭제
    fnDeleteImage() {
      //유효성 검사
      if (this.listChkBox == null || this.listChkBox.length == 0) {
        this.showAlert('삭제할 항목을 선택해주세요.')
        return;
      }
      this.showConfirm('선택한 이미지를 삭제하시겠습니까?', this.fnProcDeleteImage)
    },
    //이미지 삭제 처리
    async fnProcDeleteImage() {
      const params = {
        'fileIds': this.listChkBox,
        'useChInfo': this.useCh,
      };
      await CommonUtilApi.deleteImage(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.showAlert('삭제되었습니다')
          this.listChkBox = [];
          this.fnSearch();
          this.$bvModal.hide('confirm-modal');
        } else {
          alert(result.message);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;

.sticky-header {
  max-height: 350px;
  overflow-y: auto;
}

.info {
  margin: 20px 0 0;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #DDE1E7;
  background: var(--gray50);
  list-style: none;

  li {
    @include typography.font-style(14px, 400, 180%, -0.28px);
    color: var(--gray700);
  }
}
</style>
