<template>  
  <b-modal v-model="testSendInputOpen" id="send-test-modal" content-class="service-modal" hide-header-close centered size="xs">
    <template #modal-title>
      <h5 class="modal-title">테스트 발송</h5>
      <p class="title-desc">* 테스트 발송 시 대체 발송은 하지 않습니다.</p>
    </template>

    <div class="" v-for="header in headerList" :key="header">
      <label class="mb-1 pb-2 f-body5 c-gray500">
        {{ header === 'cuid' ? 'APP 로그인ID' : (header === 'phone' ? '휴대폰번호' : header) }} <span class="require">*</span>
      </label>
      <!-- 데이터 입력 부분 -->
      <div v-for="rowIdx in loopCnt" :key="rowIdx">
        <!-- 조건에 맞게 값이 있는 경우 -->
        <div v-if="testRecvInfoLst.length >= rowIdx">
          <!-- 휴대폰번호 입력 (phone) -->
          <b-input v-if="header === 'phone'"
                  class="mb-3"
                  placeholder="010-1234-5678"
                  :ref="header + '_' + rowIdx"
                  v-model="safeTestRecvInfoLst[rowIdx-1][header]"
                  @keyup="fnOnlyNumber($event, rowIdx)"
                  autocomplete="off">
          </b-input>

          <!-- 로그인ID 입력 (cuid) -->
          <b-input v-else-if="header === 'cuid'"
                  class="mb-3"
                  placeholder="로그인ID 입력"
                  :ref="header + '_' + rowIdx"
                  v-model="testRecvInfoLst[rowIdx-1][header]">
          </b-input>

          <!-- 일반 데이터 입력 -->
          <b-input v-else
                  class="mb-3"
                  placeholder="입력해주세요"
                  :ref="header + '_' + rowIdx"
                  v-model="testRecvInfoLst[rowIdx-1].mergeData[header]">
          </b-input>
        </div>

        <!-- 빈 값일 경우 -->
        <div v-else>
          <b-input v-if="header === 'phone'"
                  placeholder="010-1234-5678"
                  :ref="header + '_' + rowIdx"
                  @keyup="fnOnlyNumber($event, rowIdx)"
                  autocomplete="off">
          </b-input>

          <b-input v-else
                  placeholder="입력해주세요"
                  :ref="header + '_' + rowIdx">
          </b-input>
        </div>
      </div>
      <AlertModal :title="alertTitle" :desc="alertDesc" />
    </div>


    <template #modal-footer>
      <b-button variant="primary" @click="fnSendTestData">발송</b-button>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>
  </b-modal>
</template>

<script>
import AlertModal from '@/components/service/modal/AlertModal.vue'

export default {
  components: { AlertModal, },
  name: "SendTestModal",
  props: {
    useCh: String,
    testSendInputOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
    requiredCuPhone: {
      type: Boolean,
      require: true,
      default: true,
    },
    requiredCuid: {
      type: Boolean,
      require: true,
      default: true,
    },
    contsVarNms : {
      type: Array,
      require: true
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function () {
        return '테스트 발송';
      }
    },
  },
  data() {
    return {
      testSendInputOpen: false,
      testRecvInfoLst: [],
      loopCnt: 3,
      headerList: [],
    }
  },
  computed: {
    safeTestRecvInfoLst() {
      this.ensureTestRecvInfoLst(this.loopCnt); // 길이 보장
      return this.testRecvInfoLst;
    },
  },
  watch: {
    contsVarNms: {
      handler: function(){
        let headerList = [];
        if(this.requiredCuid) headerList.push('cuid');
        if(this.requiredCuPhone) headerList.push('phone');
        headerList = headerList.concat(this.contsVarNms);
        this.headerList = headerList.filter((item, pos) => headerList.indexOf(item) === pos);
      },
      immediate: true, // 초기 실행
    },
    testRecvInfoLst: {
      handler: function () {
        this.ensureTestRecvInfoLst(this.loopCnt);
      },
      immediate: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('send-test-modal');
    },
    fnSetTestRecvInfoLst(testRecvInfoLst){
      this.testRecvInfoLst = Object.assign([], testRecvInfoLst);
    },
    //발송버튼 클릭시
    fnSendTestData(){
      const vm = this;
      let recvInfoLst = [];
      //let recvInfo = {};
      let hasEmptyKey = false;
      let hasEmptyProp = false;
      const alertKeyStr = (this.requiredCuid ? 'APP 로그인' : '') + (this.requiredCuid && this.requiredCuPhone ? ', ' : '') + (this.requiredCuPhone ? '휴대폰번호' : '');

      for(let idx=1; idx<=this.loopCnt; idx++){
        let recvInfo = {cuid:'',phone:'',mergeData:{}};
        hasEmptyProp = false;

        //데이터 담기
        if(!(vm.requiredCuid && !vm.fnIsEmpty(vm.$refs['cuid_'+idx][0].value))
          && !(vm.requiredCuPhone && !vm.fnIsEmpty(vm.$refs['phone_'+idx][0].value))){
          continue;
        }
        if(vm.requiredCuid){
          if(vm.fnIsEmpty(vm.$refs['cuid_'+idx][0].value)){
            hasEmptyKey = true;
            break;
          } else {
            recvInfo.cuid = vm.$refs['cuid_'+idx][0].value;
          }
        } else {
          delete recvInfo.cuid;
        }
        if(vm.requiredCuPhone){
          if(vm.fnIsEmpty(vm.$refs['phone_'+idx][0].value)){
            hasEmptyKey = true;
            break;
          } else {
            recvInfo.phone = this.$gfnCommonUtils.hpNumberRemoveDash(vm.$refs['phone_'+idx][0].value);
          }
        } else {
          delete recvInfo.phone;
        }

        vm.contsVarNms.forEach(function(varNm){
          if(vm.fnIsEmpty(vm.$refs[varNm+'_'+idx][0].value)){
            hasEmptyProp = true;
            return false;
          } else {
            recvInfo.mergeData[varNm] = vm.$refs[varNm+'_'+idx][0].value
          }
        });
        if(hasEmptyProp) break;
        recvInfoLst.push(recvInfo);
      }

      if(hasEmptyKey){
        this.alertTitle = this.componentsTitle
        this.alertDesc = alertKeyStr+'는(은) 필수입니다.'
        return;
      }
      if(hasEmptyProp){
        this.alertTitle = this.componentsTitle
        this.alertDesc = alertKeyStr+'를(을) 입력한 행은 빈값이 존재하면 안됩니다.'
        return;
      }
      if(recvInfoLst.length == 0){
        this.alertTitle = this.componentsTitle
        this.alertDesc = '수신자 정보를 입력해주세요.'
        return;
      }
      this.$parent.fnCallbackTestRecvInfoLst(recvInfoLst);
      this.fnClose();
    },
    ensureTestRecvInfoLst(rowIdx) {
      while (this.testRecvInfoLst.length < rowIdx) {
        this.testRecvInfoLst.push({
          cuid: '',
          phone: '',
          mergeData: {},
        });
      }
    }, 
    fnOnlyNumber(event, index) {
      this.ensureTestRecvInfoLst(index);
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 45 && keyCode !== 8 && (keyCode < 94 || keyCode > 105) && keyCode !== 46 && (keyCode < 37 || keyCode > 40) ) {
 					event.preventDefault();
 			}
      var hp = this.$gfnCommonUtils.hpNumberAddDash(event.target.value);
      event.target.value = hp;
      this.testRecvInfoLst[index-1].phone = hp;
    },
    //빈값확인
    fnIsEmpty(str){
      if(str) return false;
      else return true
    },
    //팝업 닫기
    fnClose(){
      this.loopCnt=3;
      this.$emit('update:testSendInputOpen', false)
    }
  }
};
</script>

<style scoped lang="scss">
.form-control + .form-control {
  margin-top: 20px;;
}
</style>
