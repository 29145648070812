<template lang="">
  <div :class="pageDivClass" class="pagination-wrap">
    <ul class="pagination">
      <li class="page-item arrow-first">
          <b-button @click="fnAllDecrease()" class="btn btn-arrow arrow-left arrow-left-double" :disabled="pageStartNum == 1">
              <IconArrowDoubleRight />
            </b-button>
          </li>

      <li class="page-item arrow-first">
        <b-button @click="fnOneDecrease()" class="btn btn-arrow arrow-left" :disabled="pageNum == 1">
          <IconArrowRight />
        </b-button>
      </li>


      <li class="page-item">
        <button v-for="(pageCntList, index) in pageCntList()" 
						@click="fnPageClick(pageCntList)" 
						:key="index" 
						:class="[pageSltClass.numberClass, pageCntList==pageNum ? 
						pageSltClass.activeClass : '']">
						{{pageCntList}}
        </button>
      </li>


      <li class="page-item arrow-next">
       <button @click="fnOneIncrease()" class="btn btn-arrow arrow-right" 
         :disabled="pageNum !== 1">
            <IconArrowRight />
        </button>
      </li>

      <li class="page-item arrow-next arrow-last">
        <button @click="fnAllIncrease()" class="btn btn-arrow arrow-right arrow-right-double" 
        :disabled="pageStartNum * pageTotalCnt >= listPageTotalCnt">
          <IconArrowDoubleRight />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import IconArrowDoubleRight from '@/components/service/icons/IconArrowDoubleRight.vue'
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue'

export default {
  components: { IconArrowDoubleRight, IconArrowRight },
  name: "Pagination",
  props: {
            listTotalCnt: Number,         // ToTal List Count
            selected: Number,              // select Box Count (10개씩 보기 ... 등)
            pageDivClass: {
              type: String,
              require: false,
            }, 
	},
	data: () => ({
		pageNum: 1,                 // 현재 페이지
		pageStartNum: 1,            // 페이징 시작 cnt (1, 11, 21 ...)
		pageTotalCnt: 10,           // 한번에 보여주는 페이징 (1,2,3,4,5,6,7,8,9,10)
    listPageTotalCnt: 1,		    // 총 페이지수
		pageCntList () {
			var list = [];
			var listTotalCnt = this.listTotalCnt;
			var selected = this.selected;

			var listPageTotalCnt = Math.ceil(listTotalCnt/selected);
			for (var i=this.pageStartNum; i<this.pageTotalCnt+this.pageStartNum; i++) {
				if (i>listPageTotalCnt) break;
				list.push(i);
			}
			return list;
		},
		pageSltClass:{
			numberClass: 'number',
			activeClass: 'active'
		}
	}),
	methods: {
		fnPageClick(pageCntList) {
			this.pageNum = pageCntList;
			this.$emit('fnClick', this.pageNum);
		},
		// 한칸 뒤로
		fnOneIncrease() {
			var totalCount = this.listTotalCnt;     // 총 리스트 수
			var curPageNum = this.pageNum;          // 현재 페이지 위치
			var selected = this.selected;           // select Box Count
			var listPageTotalCnt = Math.ceil(totalCount/selected);    // 총 페이지 수
			var curPageMax = this.pageTotalCnt+this.pageStartNum-1;        // 현재 페이지징의 Max 값

			// 페이지 수 보다 작으면 pageNum increase 후 메소드 실행
			if (listPageTotalCnt>curPageNum) {
				if (curPageNum == curPageMax) {
					// 10, 20, 30 에서 클릭시 다음 페이징으로 update
					this.pageStartNum = this.pageStartNum+this.pageTotalCnt;
					this.pageNum++;
					this.$emit('fnClick', this.pageNum);
				} else {
					this.pageNum++;
					this.$emit('fnClick', this.pageNum);
				}
			}
		},
		// 맨 뒤로
	fnAllIncrease() {
			var totalCount = this.listTotalCnt;     // 총 리스트 수
			var selected = this.selected;           // select Box Count
			var pageTotalCnt = this.pageTotalCnt;   // 한번에 출력되는 페이징 수
			this.listPageTotalCnt = Math.ceil(totalCount/selected);    // 총 페이지 수
			var pageBlockCnt = Math.ceil(this.listPageTotalCnt/pageTotalCnt);
			pageBlockCnt--;
			pageBlockCnt=(pageBlockCnt*pageTotalCnt)+1;

			this.pageStartNum = pageBlockCnt;
			this.pageNum = this.listPageTotalCnt;
			this.$emit('fnClick', this.pageNum);
		},
		// 한칸 앞으로
		fnOneDecrease() {
			var curPageNum = this.pageNum;          // 현재 페이지 위치
			var pageStartNum = this.pageStartNum;   // 현재 페이지 시작 Cnt

			// 1페이지 보다 클 경우에만 메소드 실행
			if (1<curPageNum) {
				if (curPageNum == pageStartNum) {
					// 11, 21, 31 에서 클릭시 전 페이징으로 update
					this.pageStartNum = this.pageStartNum-this.pageTotalCnt;
					this.pageNum++;
					this.$emit('fnClick', this.pageNum);
				} else {
					this.pageNum--;
					this.$emit('fnClick', this.pageNum);
				}
			}
		},
		// 맨 앞으로
    fnAllDecrease() {
      this.pageNum = 1;
      this.pageStartNum = 1;
      this.$emit('fnClick', this.pageNum); // 이벤트를 발생시켜 부모에서 처리
    }
	}
};
</script>
<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as t;

.pagination-wrap {
  padding-top: 20px;

  @include t.tablet {
    padding-top: 40px;
  }

  .pagination {
    @include t.flex-center;

    .page-item {
      height: 100%;

      .arrow-first,
      .arrow-prev {
        padding-right: 8px;

        @include t.tablet {
          padding-right: 20px;
        }
      }

      .arrow-next,
      .arrow-last {
        padding-right: 8px;

        @include t.tablet {
          padding-left: 20px;
        }
      }

      &:not(:last-child) {
        padding: 10px !important;

        // @include t.tablet {
        //   padding-right: 28px !important;
        // }
      }

      .page-link {
        position: relative;
        width: 12px;
        height: 100%;
        font-weight: 400;
        font-size: 0.75rem;
        color: t.color(gray600);

        @include t.tablet {
          font-size: 1rem;
        }

        &.on {
          font-weight: 700;
          color: t.color(primary300);
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 12px;
          transform: translate(-50%, -50%);
        }
      }

      // 처음
      &.arrow-first {
        .page-link {
          &::after {
            // background: url(t.$icon + "ico-pagination-first.png") no-repeat;
            background-size: 100%;
          }
        }

        &.disabled {
          .page-link {
            &::after {
              background: url(t.$icon + "ico-pagination-first-disabled.png") no-repeat;
              background-size: 100%;
            }
          }
        }
      }

      // 이전
      &.arrow-prev {
        .page-link {
          &::after {
            // background: url(t.$icon + "ico-pagination-pret.png") no-repeat;
            background-size: 100%;
          }
        }

        &.disabled {
          .page-link {
            &::after {
              background: url(t.$icon + "ico-pagination-prev-disabled.png") no-repeat;
              background-size: 100%;
            }
          }
        }
      }

      // 다음
      &.arrow-next {
        .page-link {
          &::after {
            background: url(t.$icon + "ico-pagination-next.png") no-repeat;
            background-size: 100%;
          }
        }

        &.disabled {
          .page-link {
            &::after {
              /* background: url(t.$icon + "ico-pagination-next-disabled.png")
                no-repeat;*/
              background-size: 100%;
            }
          }
        }
      }

      // 마지막
      &.arrow-last {
        .page-link {
          &::after {
            background: url(t.$icon + "ico-pagination-last.png") no-repeat;
            background-size: 100%;
          }
        }

        &.disabled {
          .page-link {
            &::after {
              /*  background: url(t.$icon + "ico-pagination-last-disabled")
                no-repeat;*/
              background-size: 100%;
            }
          }
        }
      }
    }
  }
}

button.number.active {
    font-weight: 700 !important;
    color: #6d6efa !important;
}

button.number {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 400;
    font-size: 1rem;
    color: #4b5563;
}

li.page-item.arrow-last {
    padding-left: 10px;
}

@media (max-width: 375px) {
  button.number {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 400;
    font-size: .8rem;
    color: #4b5563;
}
}
</style>
