<template lang="">
  <div class="page-resource-detail">
    <h2 class="sr-only">자료실 상세 페이지</h2>
    <h3 class="customer-title">자료실</h3>
    <div class="resource-detail tbl-detail">
      <div class="resource-header tbl-header">
        <div class="title">
          <h4 class="txt-ellipsis">{{libraryData.title}}</h4>
        </div>
        <div class="info">
          <span>운영자</span>
          <span>{{libraryData.regDt}}</span>
        </div>
      </div>
      <div class="resource-contents tbl-contents">
        <pre>
          <p v-html="libraryData.content"></p>
        </pre>

        <div v-if="libraryData.existsFileYn == 'Y'" class="attach-area">
          <div class="attach-title">첨부파일</div>
          <div v-if="libraryData.existsFileYn == 'Y'" class="file">
            <a v-if="!$gfnCommonUtils.isEmpty(libraryData.fileId1)" href="#" @click.prevent="fnDownloadLibraryFile(libraryData.fileId1)" class="txt-ellipsis">
              <i class="icon-file" alt="파일 아이콘"></i>
              <span class="txt-ellipsis">{{libraryData.fileNm1}}</span>
            </a>
          </div>
          <div v-if="libraryData.existsFileYn == 'Y'" class="file">
            <a v-if="!$gfnCommonUtils.isEmpty(libraryData.fileId2)" href="#" @click.prevent="fnDownloadLibraryFile(libraryData.fileId2)" class="txt-ellipsis">
              <i class="icon-file" alt="파일 아이콘"></i>
              <span class="txt-ellipsis">{{libraryData.fileNm2}}</span>
            </a>
          </div>
          <div v-if="libraryData.existsFileYn == 'Y'" class="file">
            <a v-if="!$gfnCommonUtils.isEmpty(libraryData.fileId3)" href="#" @click.prevent="fnDownloadLibraryFile(libraryData.fileId3)" class="txt-ellipsis">
              <i class="icon-file" alt="파일 아이콘"></i>
              <span class="txt-ellipsis">{{libraryData.fileNm3}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
        <router-link :to="{ name: 'library'}">
            <div class="btn-go-list">목록</div>
        </router-link>
  </div>
</template>

<script>
import mainApi from "@/modules/main/service/mainApi.js";

export default {
  name: 'libraryDetail',

  props: {
    libraryId: {
      type: String,
      require: true,
    },
    componentsTitle: {
      type: String,
      require: false,
    },
  },

  data() {
    return {
      libraryData: {}
    }
  },

  async mounted() {
    // 페이지가 마운트되었을 때 자료실 ID가 변경되었는지 확인하고 데이터를 가져옴
    await this.fnSelectLibraryInfo();
  },

  watch: {
    // 자료실 ID가 변경되면 데이터를 다시 가져옴
    libraryId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fnSelectLibraryInfo();
        }
      }
    }
  },

  methods: {
    fnSelectLibraryInfo(){
      if(!this.$gfnCommonUtils.isEmpty(this.libraryId)){
        const vm = this;
        const params = {
          libraryId: this.libraryId
        };
        mainApi.selectLibraryList(params).then(response =>{
          const result = response.data;
          if(result.success) {
            result.data.forEach(function(obj){
              vm.libraryData = obj;
            });
          } else {
            alert(this.componentsTitle, result.message);
          }
        });
      }
    },

    fnDownloadLibraryFile(fileId){
      if(!this.$gfnCommonUtils.isEmpty(fileId)){
        const params = {
          fileId: fileId
        };

        mainApi.procDownloadLibraryFile(params).catch((error) => {
          if(error && error.response && error.response.status == 404){
            alert(this.componentsTitle, '해당 파일이 존재하지 않습니다.')
          } else {
            alert(this.componentsTitle, '파일 다운로드에 실패하였습니다.')
          }
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as e;

.landing-wrapper pre {
    font-family: inherit;
    font-size: 1em;
}

.btn-go-list {
  width: 107px;
  height: 32px;
  margin-top: 20px;
  border-radius: 6px;
  background: e.color(gray600);
  position: relative;
  left: 350%;
  font-weight: 400;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  line-height: 32px;

  @include e.tablet {
    width: 160px;
    height: 52px;
    margin-top: 40px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 52px;
  }
}

.page-resource-detail {
  padding-top: 60px;
  @include e.desktop {
    padding-top: 100px;
  }
  .resource-detail {
    gap: 0;
    .resource-header {
      .title {
        display: block;
      }
    }
    .resource-contents {
      width: 100%;
      padding-top: 20px;
      .attach-area {
        padding-top: 40px;
        .attach-title {
          padding-bottom: 20px;
          border-bottom: 1px solid e.color(gray300);
          font-weight: 700;
          font-size: 1.125rem;
          color: e.color(gray700);
          line-height: 140%;
          @include e.tablet {
            font-size: 1.5rem;
          }
        }
        .file {
          @include e.flex(row, null, center);
          padding-top: 20px;
          @include e.tablet {
            padding-top:30px;
          }
          .txt-ellipsis {
            display: flex;
            span {
              padding-left: 10px;
              font-weight: 500;
              font-size: 1.3rem;
              color: #6d6efa;
            }
          }
          .icon-file {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            @include e.tablet {
              width: 24px;
              height: 24px;
            }
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: url(e.$icon + "ico-file-violet.png") no-repeat;
              background-size: 100%;
              @include e.tablet {
                width: 24px;
                height: 24px;
              }
            }
          }
          > span {
            padding-left: 4px;
            font-weight: 500;
            font-size: 0.875rem;
            color: e.color(primary300);
            @include e.tablet {
              padding-left: 12px;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
.btn-go-list  {
    left: 100%;
  }

.resource-contents {
    width: 100%;
    padding-top: 0px !important;
}

.landing-wrapper pre {
    width: 250px !important;
    white-space: break-spaces;
}

.landing-wrapper .txt-ellipsis {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: unset;
  }
  .file {
      .txt-ellipsis {
          display: flex;
            span {
              padding-left: 10px;
              font-weight: 500;
              font-size: 1rem !important;
              color: #6d6efa;
            }
         }
  }
}

@media (max-width: 375px) {
.btn-go-list  {
    left: 105%;
  }

  .resource-contents {
      width: 100%;
      padding-top: 0px !important;
      .attach-area {
        padding-top: 0px !important;
  }
}    

.landing-wrapper pre {
    width: 300px;
    white-space: break-spaces;
}
  
.landing-wrapper.txt-ellipsis {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: unset;
  }  
  .file {
      .txt-ellipsis {
          display: flex;
            span {
              padding-left: 10px;
              font-weight: 500;
              font-size: 1rem !important;
              color: #6d6efa;
            }
         }
  }
}
</style>
