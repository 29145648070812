<template>
  <nav id="sidebar" class="text-white" :class="{'hide': !isSidebarOpen}">
      <div class="sidebar-header d-flex align-items-center justify-content-between">
          <router-link to="/ac/home" class="logo">
              <img src="@/assets/images/service/logo.svg" alt="EC-톡톡" />
          </router-link>
          <button type="button" class="btn btn-icon border-0" @click="toggleSidebar">
              <img src="@/assets/images/service/icon/icon-list.svg" alt="메뉴 여닫기" />
          </button>
      </div>

      <input id="M_svcTypeCd" type="hidden" value="UC">
      <input id="M_roleCd" type="hidden" value="OWNER">
      <input id="M_menusCd" type="hidden" value="UC_SMS_TPL">
      <input class="ml100" id="M_subCnt" type="hidden" value="0">
      <!-- 사용자 정보 -->
      <div class="sidebar-info">
          <p class="f-title3 text-truncate">{{ userCompany }}</p>
          <b-dropdown id="dropdown-dropright" dropright text="Drop-Right" variant="primary" class="w-100 user-dropdown">
              <template #button-content>
                  <span class="d-flex align-items-center">
                      <icon-user-rounded />
                      <span class="name">{{ userName }} 님</span>
                  </span>
                  <icon-arrow-right />
              </template>
              <!-- 고객사 정보 -->
              <b-dropdown-item-button v-b-modal.client-info-modal @click="fnCheckPwd('corp')">고객사 정보</b-dropdown-item-button>
              <b-dropdown-divider></b-dropdown-divider>
              <!-- 회원 정보 -->
              <!-- <b-dropdown-item-button v-b-modal.user-info-modal @click="fnCheckPwd('member')">회원정보</b-dropdown-item-button> -->
              <b-dropdown-item-button v-b-modal.password-modal @click="fnCheckPwd('member')">회원정보</b-dropdown-item-button>
              <b-dropdown-divider></b-dropdown-divider>
              <!-- 나의 문의내역 -->
              <b-dropdown-item router-link to="/ac/consoleQnA">나의 문의내역</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <!-- 로그아웃 -->
              <b-dropdown-item href="#" @click="clickLogout">로그아웃</b-dropdown-item>
          </b-dropdown>
      </div>

      <!-- 사용자일 경우 메뉴 -->
    <ul class="list-unstyled sidebar-menu">
        <li>
            <b-button v-b-toggle.collapse-user class="d-flex align-items-center justify-content-between menu-title">
                <span>서비스</span>
                <IconArrowDown />
            </b-button>
            <b-collapse id="collapse-user" class="menu-list" visible>
                <li v-for="item in treeData.filter(item => item.menusLevel === '1')" :key="item.menusCd">
                    <router-link
                    v-if="item.webUrl"
                    :to="{ path: item.webUrl }"
                    :id="'M_' + item.menusCd"
                    :class="['d-flex align-items-center menu-item', { active: isMenuActive(item.menusName) }]">
                    <component :is="getIconComponent(item.menusCd)" class="navIcon" />
                    <span>{{ item.menusName }}</span>
                    </router-link>
                </li>
            </b-collapse>
        </li>

        <li v-if="isAdmin" class="menu-divider">
            <hr />
        </li>

        <!-- 관리자 메뉴 -->
        <li v-if="isAdmin">
            <b-button v-b-toggle.collapse-admin-manage class="d-flex align-items-center justify-content-between menu-title">
                <span>관리</span>
                <IconArrowDown />
            </b-button>
            <b-collapse id="collapse-admin-manage" class="menu-list" :visible="false">
                <li v-for="item in treeDataAC.filter(item => item.menusLevel === '1')" :key="item.menusCd">
                    <router-link
                    v-if="item.webUrl"
                    :to="{ path: item.webUrl }"
                    :id="'M_' + item.menusCd"
                    :data-r="item.r"
                    :data-w="item.w"
                    :class="['d-flex align-items-center menu-item', { active: isMenuActive(item.menusName) }]">
                    <component :is="getIconComponent(item.menusCd)" class="navIcon" />
                    <span>{{ item.menusName }}</span>
                    </router-link>
                </li>
            </b-collapse>
        </li>
    </ul>

    <corpInfoPopup />
    <myPagePopup :memberInfo="memberInfo" :popReset="popReset" />
    <PasswordModal />
  </nav>
</template>

<script>
import IconUserRounded from '@/components/service/icons/IconUserRounded.vue'
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue'
import IconSend from '@/components/service/icons/IconSend.vue'
import IconMenuSearch from '@/components/service/icons/IconMenuSearch.vue'
import IconTemplate from '@/components/service/icons/IconTemplate.vue'
import IconStats from '@/components/service/icons/IconStats.vue'
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import IconChannel from '@/components/service/icons/IconChannel.vue'
import IconAPIKey from '@/components/service/icons/IconAPIKey.vue'
import IconUser from '@/components/service/icons/IconUser.vue'
import IconSendInfo from '@/components/service/icons/IconSendInfo.vue'
import IconPay from '@/components/service/icons/IconPay.vue'
import IconAddress from '@/components/service/icons/IconAddress.vue'
import IconCall from '@/components/service/icons/IconCall.vue'
import IconAlarm from '@/components/service/icons/IconAlarm.vue'
import IconGraph from '@/components/service/icons/IconGraph.vue'
import '@/assets/scss/service/sidebar.scss';

import loginApi from '@/modules/login/service/api';
import tokenSvc from '@/common/token-service';
import homeApi from '@/modules/acHome/service/api';
import confirm from "@/modules/commonUtil/service/confirm.js";
import api from '@/modules/login/service/api';
import chkPwdPopup from '@/modules/myPage/components/bp-chkPassword.vue';
import myPageApi from '@/modules/myPage/service/myPageApi';
import myPagePopup from '@/modules/myPage/components/bp-myPagePopup.vue';
import corpInfoPopup from '@/modules/myPage/components/bp-corpInfoPopup.vue';
import PasswordModal from '@/components/service/user/PasswordModal.vue';

export default {
  data() {
    return {
        isAdmin: false,
        loginId: '',
        isMyPageOpen: false,
        isChkPwdOpen: false,
        isCorpInfoOpen: false,
        treeData: [],
        treeDataAC: [],
        prdData: [],
        showOption: {
            display: 'none'
        },
        roleCd: '',
        isLogin: false,
        isErrPage: false,
        memberInfo: {},
        corpInfo: {},
        popReset: 0,
        type: "",
        userName: "",
        userCompany: "",
    }
  },
  created() {
      const token = tokenSvc.getToken();
      let findRole = ""
      if (token) {
          this.isLogin = true;
          this.svcTypeCd = token.principal?.svcTypeCd || '';
          this.loginId = token.principal?.loginId || '';
          findRole = token.principal?.role;
          this.userName = token.principal.username;
          this.userCompany = token.principal.corpName;
      } else {
          this.isLogin = false;
          this.svcTypeCd = '';
          this.loginId = '';
      }

      if (findRole === 'OWNER' || findRole.toLowerCase() === 'admin') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }

      let curPage = location.pathname;
      if (this.isLogin && curPage != "/login") {
          this.$store.commit("login/isLogin", true);
      } else {
          this.$store.commit("login/isLogin", false);
          this.isLogin = false;
          this.svcTypeCd = '';
      }
  },
  name: 'SideNavBar',
  props: {
      isSidebarOpen: {
          type: Boolean,
          required: true
      },
      toggleSidebar: {
          type: Function,
          required: true
      }
  },
  components: {
      IconUserRounded,
      IconArrowRight,
      IconSend,
      IconMenuSearch,
      IconTemplate,
      IconStats,
      IconArrowDown,
      IconChannel,
      IconAPIKey,
      IconUser,
      IconSendInfo,
      IconPay,
      IconAddress,
      IconCall,
      IconAlarm,
      IconGraph,
      myPagePopup,
      corpInfoPopup,
      chkPwdPopup,
      PasswordModal,
  },
  mounted() {
      this.fnGetCorpInfo();
      this.fnMenuList();
      this.fnMenuACList();
      this.init();
  },
  methods: {
    isMenuActive(menuName) {

        const activePaths = {
            '발송': [
                '/uc/message/sendSms',
                '/uc/rcsTemplateSend',
                '/uc/message/sendAlimTalk',
                '/uc/message/multiSendList'
            ],
            '조회': [
                '/uc/messageStatus',
                '/uc/webSend'
            ],
            '템플릿': [
                '/uc/template/multiSendTemplateList',
                '/uc/template/smsTemplateList',
                '/uc/template/rcsTemplateList',
                '/uc/template/alimTalkTemplateList',
                '/uc/template/multiSendTemplateManage',
                '/uc/template/smsTemplateManage',
                '/uc/template/rcsTemplateManage',
                '/uc/template/alimTalkTemplateManage'
            ],
            '통계': [
                '/uc/statisticsUser/realTime',
                '/uc/statisticsUser/sendByDay',
                '/uc/statisticsUser/sendByMonth'
            ],
            '채널 관리': [
                '/ac/channel/rcs',
                '/ac/channel/kakao',
            ],
            'API KEY': [
                '/ac/apikey',
            ],
            '사용자': [
                '/ac/user/manage',
            ],
            '발신정보': [
                '/ac/sendInfo/msgSendNumber',
                '/ac/sendInfo/rcsSendNumber',
            ],
            '정산': [
                '/ac/cash/cashMain',
                '/ac/cash/history',
                '/ac/cash/serviceHist',
            ],
            '주소록': [
                '/ac/address/manage',
                '/ac/address/receiver/manage',
            ],
            '발신제한': [
                '/ac/sendLimit/spam',
                '/ac/sendLimit/sendLimitAmount',
                '/ac/sendLimit/rejct080Num',
            ],
            '알람': [
                '/ac/alarm/alarm',
                '/ac/alarm/receptGroup',
                '/ac/alarm/recipient',
                '/ac/alarm/alarm/success',
                '/ac/alarm/alarm/inflow',
                '/ac/alarm/alarm/ip',
                '/ac/alarm/alarm/prepayment',
                '/ac/alarm/alarm/limits',
                '/ac/alarm/alarm/average',
            ],
            '이용현황': [
                '/ac/use/state',
            ]
        };

        return activePaths[menuName] && activePaths[menuName].some(path => this.$route.path.startsWith(path));
    },
    getIconComponent(menusCd) {
        const iconMap = {
            'UC_SND_MSG': 'IconSend',
            'UC_SND_RCV': 'IconMenuSearch',
            'UC_MNG_TPL': 'IconTemplate',
            'UC_SR_STATIS': 'IconStats',
            'AC_CHM': 'IconChannel',
            'AC_API': 'IconAPIKey',
            'AC_USER': 'IconUser',
            'AC_SEND_INFO': 'IconSendInfo',
            'AC_SETTLE_MNG': 'IconPay',
            'AC_ADDR_MNG': 'IconAddress',
            'AC_ADD_FUN': 'IconCall',
            'AC_ALARM': 'IconAlarm',
            'AC_USE_MNG': 'IconGraph'
        };

        const icon = iconMap[menusCd] || 'IconUser';
        return icon;
    },
    async fnGetCorpInfo() {
        this.roleCd = tokenSvc.getToken().principal.role;
        await homeApi.selectCorpInfo({}).then(response => {
            var result = response.data;
            if (result.success) {
                this.corpInfo = result.data;
                jQuery('#M_subCnt').val(this.corpInfo.subCnt);
            } else {
                confirm.fnAlert("", result.message);
            }
        });
    },
    init() {
        var checkToken = setInterval(function() {
            var token = tokenSvc.getToken();
            if (token == null) {
                window.top.location.href = '/login';
                clearInterval(checkToken);
            }
        }, 2000);
        var params = {
            userId: tokenSvc.getToken().principal.userId
        };
        myPageApi.selectMemberInfo(params).then(response => {
            var result = response.data.data;
            this.memberInfo = result;
            this.popReset = this.popReset + 1;
            this.changeColor(this.memberInfo.theme)
        });
    },
    fnMenuList() {
        var params = {
            "user_id": tokenSvc.getToken().principal.userId,
            "role_cd": tokenSvc.getToken().principal.role,
            "svc_type_cd": 'UC'
        };

        api.getMenuForRole(params).then(response => {
            var result = response.data;
            if (result.success) {
                let mergedData = {};
                result.data.forEach(item => {
                    if (!mergedData[item.menusCd]) {
                        mergedData[item.menusCd] = {
                            ...item,
                            r: false,
                            w: false
                        };
                    }
                    if (item.activityCd.includes('READ')) {
                        mergedData[item.menusCd].r = true;
                    }
                    if (item.activityCd.includes('SAVE')) {
                        mergedData[item.menusCd].w = true;
                    }
                });
                this.treeData = Object.values(mergedData);
            }
        });
    },
    fnMenuACList() {
        var params = {
            "user_id": tokenSvc.getToken().principal.userId,
            "role_cd": tokenSvc.getToken().principal.role,
            "svc_type_cd": 'AC'
        };

        api.getMenuForRole(params).then(response => {
            var result = response.data;
            if (result.success) {
                let mergedData = {};
                result.data.forEach(item => {
                    if (!mergedData[item.menusCd]) {
                        mergedData[item.menusCd] = {
                            ...item,
                            r: false,
                            w: false
                        };
                    }
                    if (item.activityCd.includes('READ')) {
                        mergedData[item.menusCd].r = true;
                    }
                    if (item.activityCd.includes('SAVE')) {
                        mergedData[item.menusCd].w = true;
                    }
                });
                this.treeDataAC = Object.values(mergedData);
            }
        });
    },
    getChildren(parentMenusCd) {
        return this.treeData.filter(item => item.parMenusCd === parentMenusCd);
    },
    getChildrenAC(parentMenusCd) {
        return this.treeDataAC.filter(item => item.parMenusCd === parentMenusCd);
    },
    clickLogout() {
        alert("로그아웃 하시겠습니까?");
        this.logoutPrc();
    },
    logoutPrc() {
        loginApi.logout().then(response => {
            if (response.data.success) {
                this.$router.push({
                    path: "/login"
                });
            }
        });
    },
    fnMyPage() {
        var params = {
            userId: tokenSvc.getToken().principal.userId
        };
        myPageApi.selectMemberInfo(params).then(response => {
            var result = response.data.data;
            this.memberInfo = result;
            this.popReset = this.popReset + 1;
        });
        this.$bvModal.show('user-info-modal');
    },
    fnCheckPwd(type) {
        this.popReset = this.popReset + 1;
        this.type = type;
        this.$bvModal.show('password-modal');
    },
    fnMyCorp() {
        var params = {
            userId: tokenSvc.getToken().principal.userId
        };
        myPageApi.selectCorpInfo(params).then(response => {
            var result = response.data;
            if (result.success) {
                this.corpInfo = result.data;
                this.popReset = this.popReset + 1;
            }
        });
        this.$bvModal.show('corpInfoPopup');
    },
    changeColor(color) {
      /*
      setCorpColor 사용할 수 있도록 개선 필요
      1. _variables.scss 에 해당 색상 정의
      2. main.css 에서 해당 색상 로딩
      */
      const root = document.documentElement;
      var colorList = ['primary', 'primary50', 'primary100', 'primary200', 'primary300', 'primary400', 'primary500', 'primary600', 'primary700', 'primary800'];
      colorList.forEach(element => {
        const newColorValue = getComputedStyle(root).getPropertyValue('--' + color + '-' + element).trim();
        root.style.setProperty('--' + element, newColorValue); // CSS 변수 설정
      });

      // 배경색상 변경
      const newColorValue = getComputedStyle(root).getPropertyValue('--' + color + '-bgColor').trim();
      root.style.setProperty('--bgColor', newColorValue); // CSS 변수 설정

      // livechat 색상변경
      const livechatColor = getComputedStyle(root).getPropertyValue('--primary500').trim();
      const livechatBgColor = getComputedStyle(root).getPropertyValue('--primary50').trim();
      window.RocketChat(function () {
        this.setTheme({
          color: livechatColor, // widget title background color
          title: "EC-톡톡 실시간상담", // default widget title when the status of service is online
          position: 'left', // Already mentioned, can be left or right
          background: livechatBgColor, // Already mentioned, follows CSS standards
        });
      });

    }
  }
};
</script>

<style scoped lang="scss">
    .menu-divider {
      padding: 0 36px;

      hr {
        margin: 12px 0;
        border-color: var(--white);
        opacity: .2;
      }
    }
</style>
