<template>  
  <b-modal scrollable id="select-talk-template-modal" title="템플릿 선택" hide-header-close size="xl" centered content-class="service-modal select-template-modal">
    <div class="d-flex">
      <div class="section">
        <p class="m-0 mb-1 pb-3 f-body1 c-gray700"><span class="require">*</span>카카오 채널</p>

        <div class="d-flex align-items-center dropdown-container">
          <label class="f-body5 c-gray500 form-label">채널선택</label>
          <b-dropdown id="brand-dropdown" variant="secondary" class="brand-dropdown">
            <template #button-content>
              <span>{{ kkoChId || "선택하세요" }}</span>
              <IconArrowDown />
            </template>
            <b-dropdown-item-button 
              v-for="(senderKeyInfo, idx) in senderKeyList" 
              :key="idx" 
              @click="fnChgSenderKey(senderKeyInfo.kkoChId)"
              :disabled="propSenderKey !== ''">
              {{ senderKeyInfo.kkoChId }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>

        <hr class="hr">

        <label for="template" class="m-0 f-body5 c-gray500">템플릿 유형</label>
        <b-form-group class="radio-group">
          <b-form-radio-group inline v-model="tmpLtEmphasizeType">
            <b-form-radio name="type" value="">전체</b-form-radio>
            <b-form-radio name="type" value="NONE">기본형</b-form-radio>
            <b-form-radio name="type" value="TEXT">강조표기형</b-form-radio>
            <b-form-radio name="type" value="IMAGE">이미지형</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <label for="template" class="m-0 f-body5 c-gray500">템플릿 검색</label>
        <b-input-group class="w-100">
          <b-form-input 
            id="template" 
            v-model="searchData.searchText" 
            @keypress.enter="fnSearch" 
            placeholder="검색어를 입력하세요">
          </b-form-input>
          <b-input-group-append>
            <b-button variant="dark" @click="fnSearch">조회</b-button>
          </b-input-group-append>
        </b-input-group>

        <div class="d-flex justify-content-between">
          <label class="mb-1 pb-2 f-body5 c-gray500">템플릿 이름 (템플릿 제목)</label>
          <span class="f-body5 c-gray500">총 <span class="text-primary">총 {{totCnt}}건</span></span>
        </div>
        <div class="dropdown b-dropdown template-list">
          <ul class="dropdown-menu show">
            <li
              @click="fnGetTemplateInfo(idx, templateInfo.tmpltCode)"
              v-for="(templateInfo, idx) in templateList"
              :key="idx"
            >{{templateInfo.tmpltName}}</li>
            <li v-if="templateList.length == 0" class="bb-ddd pd5 text-center">검색된 내용이 없습니다.</li>
          </ul>
        </div>
      </div>
      <div class="section">
        <p class="m-0 mb-1 pb-3 f-body1 c-gray700">템플릿 내용</p>
        <dl class="template-content">
          <dt>카카오 채널</dt>
          <dd>{{templateData.kkoChId}}</dd>
        </dl>
        <dl class="template-content">
          <dt>템플릿명</dt>
          <dd>{{templateData.tmpltName}}</dd>
        </dl>
        <dl class="template-content">
          <dt>템플릿강조제목</dt>
          <dd>{{templateData.tmpltEmpsTitle}}</dd>
        </dl>
        <dl class="template-content">
          <dt>템플릿강조부제</dt>
          <dd>{{templateData.tmpltEmpsSubTitle}}</dd>
        </dl>
        <dl class="template-content">
          <dt>내용</dt>
          <dd>{{templateData.tmpltContent}}</dd>
        </dl>
      </div>
      <div class="section">
        <PreviewTalk>
          <div class="w-100">
            <div v-if="templateData.emphasizeType == 'TEXT'">
              <p v-if="!$gfnCommonUtils.isEmpty(templateData.tmpltEmpsSubTitle)">{{templateData.tmpltEmpsSubTitle}}</p>
              <p v-if="!$gfnCommonUtils.isEmpty(templateData.tmpltEmpsTitle)">{{templateData.tmpltEmpsTitle}}</p>
            </div>
            <div v-if="templateData.emphasizeType == 'IMAGE'">
              <div v-if="templateData.templateImageUrl != ''" 
                  :style="{
                    padding: '48px',
                    borderRadius: '0px',
                    backgroundImage: 'url(' + templateData.templateImageUrl + ')',
                    backgroundSize: 'cover',  // 이미지가 컨테이너 크기에 맞춰 자동 크기 조정
                    backgroundPosition: 'center',  // 이미지가 중앙에 위치하도록 설정
                    backgroundRepeat: 'no-repeat',  // 이미지 반복하지 않도록 설정
                    height: '100%',  // 부모 컨테이너의 높이에 맞추기
                    width: '100%'   // 부모 컨테이너의 너비에 맞추기
                  }">
              </div>
            </div>
            <p class="f-body6">{{templateData.tmpltContent}}</p>
            <div v-for="(buttonInfo, idx) in templateData.buttonList" :key="idx" style="margin-bottom: 5px;">
              <b-button 
                v-if="!$gfnCommonUtils.isEmpty(buttonInfo.name)" 
                variant="secondary" 
                class="w-100">
                {{ buttonInfo.name }}
              </b-button>
            </div>        
          </div>
        </PreviewTalk>
      </div>
      <AlertModal :title="alertTitle" :desc="alertDesc" />
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="fnSelectTemplate">템플릿 선택</b-button>
      <b-button variant="outline-primary" @click="closeModal">취소</b-button>
    </template>
  </b-modal>
</template>

<script>
import PreviewTalk from '@/components/service/preview/PreviewTalk.vue';
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import templateApi from "@/modules/template/service/templateApi.js";
import AlertModal from '@/components/service/modal/AlertModal.vue';

export default {
  components: { PreviewTalk, IconArrowDown, AlertModal, },
  name: "selectTalkTemplateModal",
  props: {
    alimTalkTemplateOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return '템플릿 선택';
      }
    },
    propSenderKey : {
      type: String,
      require: false,
      default: ""
    }
  },
  data() {
    return {
      channel: '',
      type: 'all',
      totCnt:0,
      senderKeyList: [],
      templateList:[],
      templateData:{buttonList:[]},
      searchData:{
        useCh : 'ALIMTALK',
        senderKeyType: 'S',
        senderKey: '', //카카오채널
        searchCondi : 'tmpltName',
        searchText : '',
        searchTmpltStatCodes: ['A'],
        pageNo : 1,
        listSize: 999999999
      },
      tmpLtEmphasizeType: '',
      kkoChId:"",
      alertTitle: '템플릿 선택',
      alertDesc: '',
    }
  },
  watch: {
    alimTalkTemplateOpen(val){
      if(!val){
        this.fnResetData();
      }
    }
  },
  mounted() {
    this.fnSelectSenderKeyList();
  },
  methods: {
    fnResetData(){
      Object.assign(this.$data, this.$options.data());
    },
    //템플릿선택
    fnSelectTemplate(){
      if(Object.keys(this.templateData).length == 0 || this.$gfnCommonUtils.isEmpty(this.templateData.tmpltCode)){
        this.alertDesc = '템플릿을 선택해주세요.'; 
        this.$bvModal.show('alert-modal');
        return;
      }
      this.$emit('select-template', this.templateData);
      this.closeModal();
    },
    //템플릿 리스트 선택
    fnGetTemplateInfo(idx, tmpltCode){
      console.log('fnGetTemplateInfo')
      let sltData;
      if(this.templateList[idx].tmpltCode == tmpltCode){
        sltData = this.templateList[idx];
      } else {
        this.templateList.forEach(function(){
          if(this.tmpltCode == tmpltCode){
            sltData = this;
            return false;
          }
        });
      }

      sltData.buttonList = [];
      console.log(sltData.tmpltInfo)
      let tmpltInfo = JSON.parse(sltData.tmpltInfo);
      sltData.emphasizeType = tmpltInfo.templateEmphasizeType;
      sltData.tmpltEmpsTitle = tmpltInfo.templateTitle;
      sltData.tmpltEmpsSubTitle = tmpltInfo.templateSubtitle;
      sltData.tmpltContent = tmpltInfo.templateContent;
      sltData.buttonList = tmpltInfo.buttons;
      sltData.templateImageUrl = tmpltInfo.templateImageUrl;

      this.templateData = Object.assign({}, sltData);
      this.templateData.tmpltInfo = '';
      console.log(this.templateData)
    },
    fnChgSenderKey(event){
      const selectedValue = event;
      this.kkoChId = selectedValue;
    },
    //템플릿 리스트 검색
    fnAlimTalkPopOpen() {
      let propSenderKey = this.propSenderKey;
      if(propSenderKey!="") {
        this.searchData.senderKey = propSenderKey;
        this.fnSearch();
      }
    },
    async fnSearch(){
      console.log(this.tmpLtEmphasizeType)
      console.log(this.kkoChId)
      var param = Object.assign({}, this.searchData);
       if(this.$gfnCommonUtils.isEmpty(this.kkoChId)){
         this.alertDesc = '카카오 채널 키를 선택해주세요.'; 
         this.$bvModal.show('alert-modal');
         return;
       }
      param.tmpLtEmphasizeType = this.tmpLtEmphasizeType;
      param.kkoChId = this.kkoChId;

      await templateApi.selectAlimTalkTmpltList(param).then(response =>{
        const result = response.data;
        if(result.success) {
          console.log(result)
          this.templateList = result.data;
          this.totCnt = result.pageInfo.totCnt;
          if(result.data.length === 0){
            this.alertDesc = '검색된 내용이 없습니다'; 
            this.$bvModal.show('alert-modal');
          }
        } else {
          this.alertDesc = result.message; 
          this.$bvModal.show('alert-modal');
        }
      });
    },
    //카카오톡 발신 프로필키 리스트 조회 (카카오채널 리스트, 템플릿 화면)
    fnSelectSenderKeyList(){
      const params = {kkoSvc: this.searchData.useCh, senderKeyType: this.searchData.senderKeyType};
      templateApi.selectSenderKeyList(params).then(response => {
        const result = response.data;
        if(result.success) {
          this.senderKeyList = Object.assign([], result.data);
          // for(let i = 0 ; this.senderKeyList.length > i ; i++){
          //     this.kkoChId = this.senderKeyList[i].kkoChId;
          // }
        } else {
          this.alertDesc = result.message; 
          this.$bvModal.show('alert-modal');
        }
      });
    },
    //팝업 닫기
    fnClose(){
      this.$emit('update:alimTalkTemplateOpen', false)
    },
    closeModal() {
      this.$bvModal.hide('select-talk-template-modal');
    },
    setChannel(value) {
      this.channel = value;
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/message.scss';
.custom-control + .custom-control {
  margin-left: 20px;
}
.radio-group {
  margin-top: 12px;
  margin-bottom: 20px;
}
</style>
