<template>
  <div class="datepicker-container">
    <DatePicker v-model="selectedDate" :type="mode" :lang="lang" :placeholder="placeholderText"  @change="onDateChange" ref="datepicker">
      <template slot="icon-calendar">
        <IconCalendarBlank />
      </template>
    </DatePicker>
  </div>
</template>

<script>
import IconCalendarBlank from '@/components/service/icons/IconCalendarBlank.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  components: { IconCalendarBlank, DatePicker },
  name: "CustomDatepicker",
  props: {
    mode: {
      type: String,
      default: "day", // 기본값은 일별 선택
    },
  },
  data() {
    return {
      selectedDate: null,
      formattedDate: null,
      dateFormatOptions: { year: 'numeric', month: '2-digit', day: '2-digit' }
    };
  },
  computed: {
    // `mode`에 따라 placeholder 텍스트 변경
    placeholderText() {
      return this.mode === "month" ? "YYYY-MM" : "YYYY-MM-DD";
    },
  },
  watch: {
    selectedDate(newDate) {
      if (newDate) {
        this.formattedDate = this.formatDate(newDate);
        // this.$emit("input", this.formatDate(newDate));
      } else {
        this.formattedDate = null;
      }
    }
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2); // 0부터 시작하므로 +1
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    onDateChange(date) {
      this.selectedDate = date;  // 날짜 선택 후 v-model로 값 설정
      this.$emit('input', this.formatDate(date));  // 부모에 값 전달
      // 창을 닫는 방식: v-model 값 설정 후 자동으로 닫힘
      this.$refs.datepicker.closePopup();
    },
    closeDatePicker() {
      this.$nextTick(() => {
        const datepickerPopup = this.$refs.datepicker.$el.querySelector('.mx-datepicker-popup');
        if (datepickerPopup) {
          // .mx-datepicker-popup의 display를 none으로 설정하여 달력 닫기
          datepickerPopup.style.display = 'none';
        }
      });
    },
  }
};
</script>

<style lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;

.datepicker-container {
  position: relative;
  .mx-datepicker {
    width: 100%;
  }
  .mx-input {
    width: 100%;
    height: auto;
    padding: 11px 43px 11px 15px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    background: var(--white);
    box-shadow: none;
    @include typography.font-style(14px, 400, 140%, -0.28px);
    color: var(--gray900);
    &::placeholder {
      color: var(--gray400);
    }
    &:hover {
      border-color: var(--gray500);
    }
    &.is-invalid {
      border-color: var(--red-600);
      & + .calendar-icon svg path {
        fill: var(--red-600);
      }
    }
    &:disabled {
      background-color: var(--gray50);
      & + .calendar-icon svg path {
        fill: var(--gray400);
      }
    }
  }
  .mx-icon-calendar {
    right: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
    path {
      fill: var(--white)
    }
  }
}
.mx-datepicker-main {
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    @include typography.font-style(14px, 400, 140%, -0.28px);
    color: var(--gray900);
}
.mx-table {
  &:not(.mx-table-month) {
    .cell:hover,
    .active {
      border-radius: 50%;
    }
  }
  th {
    @include typography.font-style(12px, 400, 160%, -0.24px);
    color: var(--gray500);
  }
  td {
    @include typography.font-style(14px, 400, 180%, -0.28px);
    color: var(--gray900);
    &.cell:hover,
    &.cell.active  {
      background-color: var(--primary);
      color: var(--white);
    }
  }
  .today {
    color: var(--primary);
  }
}
.mx-calendar {
  &-header-label {
    button {
      @include typography.font-style(16px, 700, 140%, -0.32px);
      color: var(--gray900);
    }
  }
  .mx-btn:hover {
    border-color: var(--primary);
    color: var(--primary);
  }
}
.mx-icon-left:before, .mx-icon-right:before, .mx-icon-double-left:before, .mx-icon-double-right:before, .mx-icon-double-left:after, .mx-icon-double-right:after {
  width: 12px;
  height: 12px;
  border-width: 3px 0 0 3px;
  border-color: var(--gray900);
}
.mx-datepicker-popup {
  border-radius: 8px;
}
</style>
