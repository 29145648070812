<template>
  <b-modal scrollable id="password-modal" title="고객사 정보" hide-header-close size="md" centered>
    <template #modal-title>
      <h5 class="modal-title">비밀번호 확인</h5>
      <p class="title-desc">* 안전을 위하여 비밀번호를 한 번 더 입력해 주시기 바랍니다.</p>
    </template>

    <div class="form-row">
      <label class="mb-0">비밀번호</label>
      <div>
        <PasswordInput v-model="password" />
      </div>
      <AlertModal :title="alertTitle" :desc="alertDesc" />
    </div>

    <template #modal-footer>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
      <b-button variant="primary" @click="fnChkPwd">확인</b-button>
    </template>
  </b-modal>
</template>

<script>
import PasswordInput from '@/components/service/form/PasswordInput.vue'
import AlertModal from '@/components/service/modal/AlertModal.vue';
import myPageApi from '@/modules/myPage/service/myPageApi';
import tokenSvc from '@/common/token-service';

export default {
components: { PasswordInput, AlertModal, },
name: 'PasswordModal',
  props: {
  },
  data() {
    return {
      password: '', // PasswordInput에서 입력된 값을 저장
      alertTitle: '',
      alertDesc: ''
    }
},
  methods: { 
    closeModal() {
      this.$bvModal.hide('password-modal');
    },
    // 비밀번호 체크
    fnChkPwd(){
      console.log(this.password)
      var params = {
        password : this.password,
        userId : tokenSvc.getToken().principal.userId
      }
      myPageApi.checkPassword(params).then(response =>{
        var result = response.data;
        console.log(result)
        if(result.success) {
          this.closeModal();
          if(this.type == 'corp'){
            this.$parent.fnMyCorp();
          } else {
            this.$parent.fnMyPage();
          }
        } else {
          this.alertDesc = result.message;
          this.$bvModal.show('alert-modal');
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/template.scss';
</style>