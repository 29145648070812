<template lang="">
  <div class="page-notice-list">
    <h2 class="sr-only">공지사항 페이지</h2>
    <div class="filter-area">
    <select 
      v-model="selected" 
      @change="onNoticeTypeChange" 
      id="landingSelect" 
      class="base-select">
      <option :value="''" :class="{ active: selected === '' }">전체</option>
      <option 
        v-for="(noticeTypeInfo, idx) in noticeTypeList" 
        :key="idx" 
        :value="noticeTypeInfo.codeVal1">
        {{ noticeTypeInfo.codeName1 }}
      </option>
    </select>
        <div class="search-box">
          <input
            v-model="keyword"
            type="search"
            placeholder="검색어를 입력해주세요"
            class="search-input base-input"
            @keypress.enter="fnSetSelPage"
          />
          <button 
            type="button" 
            class="btn-search"
            @click="fnSetSelPage">
            검색
          </button>
        </div>
    </div>
  
    <div class="notice-list">
      <h3 class="customer-title">공지사항</h3>
      <table class="tbl">
        <thead>
          <tr>
            <th class="mobileHidden">번호</th>
            <th>종류</th>
            <th>제목</th>
            <th class="mobileHidden">작성일</th>
            <th class="mobileHidden">작성자</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(contant, idx) in contants"
            :key="idx"
            :class="{
              notice_contenttr: true,
              notice_contentToptr: isTopNotice(contant),
            }"
          >
            <td class="num">{{ totCnt - offset - contant.rownum + 1 }}</td>
            <td>
              <span 
                class="label-notice" 
                :class="getNoticeClass(contant.noticeType)">
                {{ getNoticeTypeLabel(contant.noticeType) }}
              </span>
            </td>
            <td class="align-left overflow">
              <router-link :to="{ name: 'noticeDetail', params: { noticeId: contant.noticeId }}">
                  {{ contant.title }}
              </router-link>
            </td>
            <td class="date">{{ contant.regDt }}</td>
            <td class="writer">운영자</td>
          </tr>
        </tbody>
      </table>
      <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></Pagination>
    </div>
  </div>
</template>
<script>
import Pagination from "../components/MainPagination.vue";
// import Pagination from '@/components/service/Pagination.vue';
import mainApi from "@/modules/main/service/mainApi.js";

export default {
  name: "notice",
  components: { Pagination },

  data() {
    return {
      selected: '', // 초기 선택값을 빈 문자열로 설정
      keyword: '', // 검색어를 담을 변수
      searchResults: [], // 검색 결과를 담을 변수
      noticeTypeCd: '',
      noticeTypeList: [],
      contants: [],
      listSize: 10,  // 한페이지 출력 리스트 수
      pageNo: 1,  // 현재 페이징 위치
      totCnt: 0,  //전체 리스트 수
      offset: 0, //페이지 시작점
    };
  },

  mounted() {
    this.fnSelectNoticeType();
    this.fnSetNoticeTypeCd(this.noticeTypeCd);
    this.fnSelectNoticeList();
    this.fnSetSelPage();
  },

  watch: {
    selected(newVal) {
      this.fnSetNoticeTypeCd(newVal);
    }
  },

  methods: {
    onNoticeTypeChange() {
    this.pageNo = 1; // 선택 시 페이지 초기화
    this.fnSetNoticeTypeCd(this.selected);
  },

    async goToDetail(noticeId) {
      // 공지사항 상세 페이지로 이동 : 글의 ID값 받아서 이동시킴
      this.$router.push({ name: 'noticeDetail', params: { noticeId: noticeId } });
    },

      getNoticeTypeLabel(type) {
      switch (type) {
        case "INFO":
          return "안내";
        case "CHECK":
          return "점검";
        case "ERROR":
          return "장애";
        case "EVENT":
          return "행사";
        default:
          return type;
      }
    },

    getNoticeClass(type) {
      switch (type) {
        case "INFO":
          return "info";
        case "CHECK":
          return "check";
        case "ERROR":
          return "error";
        case "EVENT":
          return "event";
        default:
          return "";
      }
    },

		fnSetSelPage(){
			// 검색 조회시 1페이지로 이동
			this.pageNo = 1;
			this.fnSelectNoticeList();
		},

  fnSetNoticeTypeCd(noticeTypeCd) {
      this.noticeTypeCd = noticeTypeCd;
      this.fnSelectNoticeList();
    },

    async fnSelectNoticeList() {
      const params = {
          keyword: this.keyword,
          noticeType: this.noticeTypeCd,
          pageNo: this.pageNo,
          listSize: this.listSize
      };

      await mainApi.selectNoticeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    fnSelectNoticeType() {
      const params = {
        codeTypeCd: "NOTICE_TYPE",
        useYN: "Y"
      };

      mainApi.selectCodeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.noticeTypeList = result.data;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    isTopNotice(contant) {
      const now = new Date();
      const startDate = new Date(contant.topStartDt);
      const endDate = new Date(contant.topEndDt);
      
      return contant.topYn === 'Y' && startDate <= now && endDate >= now;
    },

    fnSearch(pageNo) {
      this.pageNo = pageNo;
      this.fnSelectNoticeList();
    },
  }
};
</script>
<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as d;

.page-notice-list {
  padding-top: 60px;

  @include d.tablet {
    padding-top: 100px;
  }

  .filter-area {
    @include d.flex-between;
    padding-bottom: 32px;

    .base-select {
      margin-right: 8px;
    }
  }

  .notice-list {
    table {
      thead {
        tr {
          th {
            &:first-child {
              width: 15%;

              @include d.desktop {
                width: 10%;
              }
            }

            &:nth-child(2) {
              width: 15%;

              @include d.desktop {
                width: 10%;
              }
            }

            &:nth-child(3) {
              width: 35%;

              @include d.desktop {
                width: 45%;
              }
            }

            &:nth-child(4) {
              width: 25%;
            }

            &:last-child {
              width: 15%;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &.num {
              @include d.tablet {
                font-size: 1.125rem;
              }
            }

            &.overflow {
              padding-left: 12px;
              font-size: 0.875rem;

              @include d.tablet {
                font-size: 1rem;
              }

              @include d.desktop {
                padding-left: 40px;
              }
            }
          }
        }
      }
    }
  }
}

#landingSelect {
          position: relative;
          width: 70px;
          height: 35px;
          padding-left: 12px;
          border-radius: 8px;
          border: 1px solid d.color(gray300);
          background: url(d.$icon + "ico-select.png") no-repeat center;
          background-position-x: calc(100% - 12px);
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 160%;
          color: d.color(gray900);
          -webkit-appearance: none;
          @include d.tablet {
            width: 160px;
            height: 44px;
            //padding: 14px 16px;
            padding-left: 16px;
            background-position-x: calc(100% - 16px);
            font-size: 0.875rem;
          }
  option {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

tr.notice_contenttr.notice_contentToptr {
    background-color: #fffee6;
}

.search-box {
  @include d.flex(row, null, center);
  .search-input {
    position: relative;
    width: 190px;
    margin-right: 8px;
    background: url(d.$icon + "ico-search.png") no-repeat center;
    background-position-x: calc(100% - 12px);
    @include d.tablet {
      width: 300px;
      margin-right: 12px;
      background-position-x: calc(100% - 16px);
    }
  }

  .btn-search {
    width: 56px;
    height: 35px;
    border-radius: 6px;
    background: d.color(gray600);
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    line-height: 35px;
    @include d.tablet {
      width: 120px;
      height: 44px;
    }
  }
}

.label-notice {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  line-height: 24px;
  @include d.tablet {
    width: 80px;
    height: 34px;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 34px;
  }

  &.event {
    border: 1px solid d.color(primary200);
    background: rgba(233, 233, 254, 0.5);
    color: d.color(primary300);
  }
  &.check {
    border: 1px solid #ff9e9e;
    background: rgba(255, 89, 79, 0.1);
    color: #ff594f;
  }
  &.error {
    border: 1px solid d.color(gray400);
    background: d.color(gray200);
    color: d.color(gray600);
  }
  &.info {
    border: 1px solid d.color(gray300);
    background: #fff;
    color: d.color(gray600);
  }
}


@media (max-width: 350px) {
  .search-box {
    @include d.flex(row, null, center);
    .search-input {
      position: relative;
      width: 170px !important;
      margin-right: 8px;
      background: url(d.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include d.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
  }

  .btn-search {
      width: 56px;
      height: 35px;
      border-radius: 6px;
      background: d.color(gray600);
      font-weight: 400;
      font-size: 0.875rem;
      color: #fff;
      line-height: 35px;
      @include d.tablet {
        width: 120px;
        height: 44px;
      }
    }
  }
}

/* 예외 */
@media (max-width: 375px) {
  .mobileHidden, .num, .date, .writer {
      display: none;
    }
  .page-notice-list .notice-list table tbody tr td.overflow {
    padding-left: 0px;
  }
  .search-box {
    @include d.flex(row, null, center);
    .search-input {
      position: relative;
      width: 190px;
      margin-right: 8px;
      background: url(d.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include d.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
    }
  }
}
</style>
