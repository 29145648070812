<template>
  <b-modal scrollable id="upload-image-modal" content-class="service-modal" hide-header-close centered size="lg">
    <template #modal-title>
      <h5 class="modal-title">이미지 업로드</h5>
      <p class="title-desc">* 광고성메시지 수신거부번호는 내용 하단에 포함됩니다. 또한 광고 표기는 제목 또는 내용에 포함되어 있어야 합니다.</p>
    </template>

    <ul class="info">
      <li>• 이미지를 사용하실 채널을 선택해 주십시오. (채널마다 이미지 형식이 다를 수 있습니다.)</li>
      <li v-if="!this.chkboxUseCh.includes('MMS') && this.chkboxUseCh.length > 0">
        • 이미지는 jpg, jpeg, png 형식만 지원합니다.
      </li>
      <li v-else-if="this.chkboxUseCh.includes('MMS')">• 이미지는 jpg, jpeg 형식만 지원합니다.</li>

      <li v-if="this.chkboxUseCh.includes('MMS')">• 이미지 용량은 300KB 이하여야 합니다.</li>
      <li v-else-if="this.chkboxUseCh.includes('FRIENDTALK') && this.wideYn == 'N'">
        • 이미지 용량은 500KB 이하여야 합니다.
      </li>
      <li v-else-if="this.chkboxUseCh.includes('RCS')">
        • 이미지 용량은 1MB 이하여야 합니다.
      </li>
      <li v-else-if="this.chkboxUseCh.includes('FRIENDTALK') && this.wideYn == 'Y'">
        • 이미지 용량은 2MB 이하여야 합니다.
      </li>
    </ul>

    <label class="mt-1 pt-3 mb-0 f-body1 c-gray900">이미지 사용 채널</label>
    <div class="checkbox-wrap">
      <b-form-checkbox v-model="chkboxUseCh" value="MMS">MMS</b-form-checkbox>
      <b-button v-b-tooltip.hover.html="mmsTooltip" variant="dark" class="btn-icon p-0 btn-tooltip">
        <IconQuestion />
      </b-button>
      <b-form-checkbox v-model="chkboxUseCh" value="RCS">RCS</b-form-checkbox>
      <b-button v-b-tooltip.hover.html="rcsTooltip" variant="dark" class="btn-icon p-0 btn-tooltip">
        <IconQuestion />
      </b-button>
      <b-form-checkbox v-model="chkboxUseCh" value="FRIENDTALK">친구톡</b-form-checkbox>
      <b-button v-b-tooltip.hover.html="talkTooltip" variant="dark" class="btn-icon p-0 btn-tooltip">
        <IconQuestion />
      </b-button>

      <b-dropdown v-if="chkboxUseCh.includes('FRIENDTALK')" v-model="wideYn" id="channel-dropdown" variant="secondary"
        class="channel-dropdown">
        <template #button-content>
          <span>{{ wideYn == 'N' ? 'Normal' : 'Wide' }}</span>
          <IconArrowDown />
        </template>
        <b-dropdown-item-button @click="wideYn = 'N';">Normal</b-dropdown-item-button>
        <b-dropdown-item-button @click="wideYn = 'Y';">Wide</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <div class="upload-area">
      <b-form-file @change="fnImagePreview" v-model="files" class="mt-3" plain ref="fileInput"></b-form-file>
      <span v-if="!imageUrl" class="f-body6 c-gray700" text>이미지 영역</span>
      <img v-if="imageUrl" :src="imageUrl" />
    </div>

    <template #modal-footer>
      <b-button @click="triggerFileInput" variant="primary">파일찾기</b-button>
      <b-button @click="fnUploadFile" variant="primary">업로드</b-button>
      <b-button @click="fnClose" variant="outline-primary">닫기</b-button>
    </template>

    <AlertModal :title="alertTitle" :desc="alertDesc" />
  </b-modal>
</template>

<script>
import IconQuestion from '@/components/service/icons/IconQuestion.vue'
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import commonUtilApi from "@/modules/commonUtil/service/commonUtilApi.js";
import AlertModal from '@/components/service/modal/AlertModal.vue'
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue'



export default {
  components: { IconQuestion, IconArrowDown, AlertModal, ConfirmModal },
  name: "UploadImageModal",
  data() {
    return {
      selectChannel: 'Normal',
      files: [],
      chkboxUseCh: [],
      imageUrl: '',
      wideYn: 'N',
      alertTitle: '',
      alertDesc: '',
    }
  },
  methods: {
    showAlert(msg) {
      this.alertDesc = msg
      this.$bvModal.show('alert-modal');
    },
    //팝업 초기화
    fnClose() {
      this.files = [];
      this.chkboxUseCh = [];
      this.imageUrl = '';
      this.wideYn = 'N';
      this.inProgress = false;
      this.$bvModal.hide('upload-image-modal');
    },
    mmsTooltip() {
      return '<ul><li>* 이미지는 jpg, jpeg 형식만 지원합니다.</li><li>* 이미지 용량은 300KB 이하여야 합니다.</li><li>* 이미지 권장 사이즈는 320*240 또는 440*330 입니다.</li></ul>'
    },
    rcsTooltip() {
      return '<ul><li>* 이미지는 jpg, jpeg 형식만 지원합니다.</li><li>* 이미지 용량은 1MB 이하여야 합니다.</li><li>* 이미지 권장 사이즈는 568*336 또는 568*528 입니다.</li></ul>'
    },
    talkTooltip() {
      return '<ul><li>* 이미지는 jpg, jpeg, png 형식만 지원합니다.</li><li>* 이미지 용량은 Normal은 500KB이하, Wide는 2MB 이하여야 합니다.</li><li>* Wide 이미지 권장 사이즈는 가로 길이 최소 800px, 세로 길이는 최소 600px 이상입니다.</li><li>* 이미지 제한 사이즌 가로:세로 비율 2:1 이상, 4:3 이하여야 합니다.</li></ul>'
    },

    //이미지미리보기
    fnImagePreview(e) {
      if (e.target.files && e.target.files.length > 0) {
        var file = e.target.files[0];
        this.imageUrl = URL.createObjectURL(file);
      } else {
        this.imageUrl = '';
      }
    },
    triggerFileInput() {
      // 파일 입력 요소 클릭
      this.$refs.fileInput.$el.click();
    },
    //파일업로드
    async fnUploadFile() {
      if (this.inProgress) {
        this.showAlert('파일업로드 처리중입니다.');
        return;
      }

      const uploadFile = this.$refs.fileInput;
      //유효성체크
      //사용채널 선택 확인
      if (this.chkboxUseCh.length == 0) {
        this.showAlert('사용채널을 선택해주세요.');
        return;
      }

      //이미지파일 선택 확인
      if (uploadFile.value.length == 0) {
        this.showAlert('파일을 등록해주세요.');
        return;
      }

      //확장자 검사
      const permitExten = 'jpg,jpeg,png'.split(',');
      const extnIdx = uploadFile.value.name.lastIndexOf('.');
      const extn = uploadFile.value.name.substring(extnIdx + 1).toLowerCase();

      if ((permitExten.indexOf(extn) < 0) || (this.chkboxUseCh.includes('MMS') && extn == 'png')) {
        this.showAlert('허용되지 않는 확장자입니다.\n(이미지는 .jpg, .jpeg, .png(MMS 제외) 확장자 지원합니다.)');
        return;
      }
      const img = new Image()
      img.src = this.imageUrl

      const imgRatio = img.height / img.width

      if (this.chkboxUseCh.includes('MMS')) {
        if (uploadFile.files[0].size > 307200) {
          this.showAlert('이미지 용량은 300KB 이하여야 합니다.');
          return;
        }
      } else if (this.chkboxUseCh.includes('FRIENDTALK') && this.wideYn == 'N') {
        if (uploadFile.files[0].size > 512000) {
          this.showAlert('이미지 용량은 500KB 이하여야 합니다.');
          return;
        }
        if (imgRatio < 0.5 || imgRatio > 0.75) {
          this.showAlert('이미지 비율은 가로:세로 2:1 이상, 4:3 이하여야 합니다.');
          return;
        }
      } else if (this.chkboxUseCh.includes('RCS') || this.chkboxUseCh.includes('PUSH')) {
        if (uploadFile.files[0].size > 1048576) {
          this.showAlert('이미지 용량은 1MB 이하여야 합니다.');
          return;
        }
      }
      else if (this.chkboxUseCh.includes('FRIENDTALK') && this.wideYn == 'Y') {
        if (uploadFile.files[0].size > 2097152) {
          this.showAlert('이미지 용량은 2MB 이하여야 합니다.');
          return;
        }
        if (imgRatio < 0.5 || imgRatio > 0.75) {
          this.showAlert('이미지 비율은 가로:세로 2:1 이상, 4:3 이하여야 합니다.');
          return;
        }
      }

      const uploadInfo = {
        useCh: this.chkboxUseCh,
        wideYn: this.wideYn
      };

      let fd = new FormData();
      fd.append('file', uploadFile.files[0]);
      fd.append('paramString', JSON.stringify(uploadInfo));

      this.inProgress = true;
      const vm = this;

      await commonUtilApi.uploadImage(fd).then(response => {
        this.inProgress = false;
        const result = response.data;

        if (result.success) {
          this.fnClose();
          this.$emit('upload-complete');
          // this.$refs.SelectImageModal.$emit('upload-complete'); 
        } else {
          if (result.message !== null || typeof (result.message) !== 'undefined') {
            this.showAlert(result.message);
          } else {
            this.showAlert('등록에 실패했습니다.');
          }
        }

      }).catch(function () {
        vm.inProgress = false;
      });

    },
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;

.info {
  margin: 20px 0 0;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #DDE1E7;
  background: var(--gray50);
  list-style: none;

  li {
    @include typography.font-style(14px, 400, 180%, -0.28px);
    color: var(--gray700);
  }
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  margin: 20px 0;

  .btn-icon {
    margin: -3px 20px 0 6px;
    background-color: var(--white);
    border: none;
  }
}

.channel-dropdown {
  width: 123px;
}

.upload-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 333px;
  border: 1px solid #DDE1E7;
  border-radius: 12px;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 3;
    opacity: 0;
  }

  img {
    height: 100%;
    z-index: 1;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
