<template>  
  <b-modal v-model="isModalVisible" scrollable id="search-address-modal" content-class="service-modal search-address-modal" hide-header-close centered size="lg">
    <template #modal-title>
      <h5 class="modal-title">수신 주소록 검색</h5>
      <p class="title-desc">* 조직 조회 후 체크된 사용자를 선택하면 수신자에 추가됩니다.</p>
    </template>

    <!-- <label id="search" class="mb-1 pb-2 f-body5 c-gray500">템플릿 검색</label> -->
    <div class="form-group">
      <b-dropdown id="search-type-dropdown" variant="secondary" class="search-type-dropdown">
        <template #button-content>
          <span>{{ searchTypeText }}</span>
          <IconArrowDown />
        </template>
        <b-dropdown-item-button
          v-for="(option, index) in searchOptions"
          :key="index"
          :class="{ active: searchType === option.value }"
          @click="selectSearchType(option)"
        >
          {{ option.text }}
        </b-dropdown-item-button>
      </b-dropdown>
      <b-input placeholder="검색" v-model="searchText"></b-input>
      <b-button variant="dark" @click="fnSearchAddrMem">검색</b-button>
    </div>

    <hr class="hr">

    <div class="card-wrap">
      <div class="card menu-tree">
        <ul class="tree-menu-list">
          <li v-for="(addrTreeData, index) in addrTreeList" :key="index">
            <button type="button" @click="toggleGroup(index)" class="btn btn-icon p-0">
              <IconOn v-if="addrTreeData.isOpen" />
              <IconOff v-else />
            </button>
            <span>{{ addrTreeData.addressName }}</span>
            <b-collapse :visible="addrTreeData.isOpen">
              <ul class="menu-list-sub">
                <li v-for="(item, itemIndex) in addrTreeData.subItems" :key="itemIndex">
                  <button type="button" class="f-body6" :class="{active: tree === item}" @click="setTree(item)">{{ item.addressName }}</button>
                </li>
              </ul>
            </b-collapse>
          </li>
        </ul>
      </div>

      <div class="table-responsive sticky-header">
        <table class="table">
          <thead>
            <tr>
              <th style="width: 40px;">
                <b-form-checkbox
                  id="selectAll"
                  name="selectAll"
                  value="selectAll"
                  @change="fnListChkAll" 
                  v-model="listAllChecked"
                  size="lg"
                ></b-form-checkbox>
              </th>
              <th class="text-center lc-1" style="width: 150px;">수신자명</th>
              <th v-if="requiredCuid" class="text-center lc-1" style="width: 150px;">APP 로그인ID</th>
              <th v-if="requiredCuPhone" class="text-center lc-1" style="width: 150px;">휴대폰번호</th>
              <th v-for="varNm in contsVarNms" :key="varNm" class="text-center lc-1" style="width: 150px;">{{varNm}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cmCuInfo, idx) in cmCuList" :key="cmCuInfo.cuInfoId">
              <td style="width: 40px;">
                <b-form-checkbox
                  type="checkbox" 
                  :id="'listCheck_'+idx" 
                  :value="cmCuInfo.cuInfoId" 
                  v-model="listChkBox" 
                  :disabled="(requiredCuid && $gfnCommonUtils.isEmpty(cmCuInfo.cuid)) || (requiredCuPhone && $gfnCommonUtils.isEmpty(cmCuInfo.hpNumber))"
                  size="lg"
                ></b-form-checkbox>
              </td>
              <td class="text-center">{{cmCuInfo.cuName}}</td>
              <td v-if="requiredCuid" class="text-left" >{{cmCuInfo.cuid}}</td>
              <td v-if="requiredCuPhone" class="text-left">{{cmCuInfo.hpNumber | phoneNumAddDash}}</td>
              <td v-for="varNm in contsVarNms" :key="varNm" class="text-center">
                <input type="text" class="inputStyle" v-model="cmCuInfo[varNm]">
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"
          ref="updatePaging" />
      </div>
      <AlertModal :title="alertTitle" :desc="alertDesc" />
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="fnSelectCu">선택</b-button>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>
  </b-modal>
</template>

<script>
import MessageApi from "@/modules/message/service/messageApi.js";
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue';
import Pagination from '@/components/service/Pagination.vue';
import IconOn from '@/components/service/icons/IconOn.vue';
import IconOff from '@/components/service/icons/IconOff.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue';

export default {
  components: { IconArrowDown, IconOn, IconOff, AlertModal, Pagination, },
  name: "SearchAddressModal",
  props: {
    isModalVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return '주소록 검색';
      }
    },
    requiredCuPhone: {
      type: Boolean,
      require: true,
      default: true,
    },
    requiredCuid: {
      type: Boolean,
      require: true,
      default: true,
    },
    contsVarNms : {
      type: Array,
      require: true
    },
  },
  data() {
    return {
      isModalVisible: false,
      searchType: 'cuName',
      searchTypeText: "수신자 명",
      searchOptions: [
        { text: "수신자 명", value: "cuName" },
        { text: "휴대폰 번호", value: "hpNumber" }
      ],
      tree: '',
      listAllChecked: false,
      listChkBox: [],
      searchCategoryId: 0,
      addrTreeList: [],
      cmCuList: [],
      listSize : 5,  // 리스트 출력 개수
      pageNo : 1,  // 현재 페이징 위치
      totCnt : 0,  //전체 리스트 수
      alertTitle: '주소록 검색',
      alertDesc: '',
    }
  },
  watch: {
    isModalVisible(newVal) {
      if (newVal) {
        this.fnGetAddrList(); // 모달이 열릴 때 실행
      }
    }
  },
  methods: {
    selectSearchType(option) {
      this.searchType = option.value;
      this.searchTypeText = option.text;
    },
    closeModal() {
      this.$emit('update:isModalVisible', false);
      this.searchTextType = 'cuName';
      this.searchText = '';
      this.searchCategoryId = '';
      this.addrTreeList = [];
      this.cmCuList = [];
      this.listSize = 5;
      this.pageNo = 1;
      this.totCnt = 0;
      this.fnResetChkbox();
      this.$bvModal.hide('search-address-modal');
    },
    toggleGroup(index) {
      this.addrTreeList[index].isOpen = !this.addrTreeList[index].isOpen;
    },
    setTree(value) {
      this.tree = value;
      this.fnAddrCatgMem(value.addressCategoryId)
    },
    fnSelectCu(){
      //유효성 검사
      if(this.listChkBox == null || this.listChkBox.length == 0){
        this.alertDesc = '수신자를 선택해주세요.';
        this.$bvModal.show('alert-modal');
        return;
      }

      const vm = this;
      let recvInfoLst = [];
      let recvInfo = {};
      let sltCuInfo = {};
      //let isValid = true;
      let inValidVarNm = '';

      this.listChkBox.forEach(function(v){
        sltCuInfo = vm.fnCmCuListGetRowById(v);
        recvInfo = {phone:'',cuid:'',mergeData:{}};

        if(vm.requiredCuid) recvInfo.cuid = sltCuInfo.cuid;
        else delete recvInfo.cuid;
        if(vm.requiredCuPhone) recvInfo.phone = sltCuInfo.hpNumber;
        else delete recvInfo.phone;

        vm.contsVarNms.forEach(function(key){
          if(vm.$gfnCommonUtils.isEmpty(sltCuInfo[key])){
            inValidVarNm = key;
            return false;
          } else {
            recvInfo.mergeData[key] = sltCuInfo[key];
          }
        });
        if(vm.$gfnCommonUtils.isEmpty(inValidVarNm) == false) return false;
        recvInfoLst.push(recvInfo);
      });
      if(this.$gfnCommonUtils.isEmpty(inValidVarNm) == false){
        this.alertDesc = '선택항목의 '+inValidVarNm+'을 모두 입력해주세요.';
        this.$bvModal.show('alert-modal');
        return;
      }

      this.$parent.fnCallbackRecvInfoLst(recvInfoLst, 'Y');
      this.alertDesc = '수신자를 추가하였습니다.';
      this.$bvModal.show('alert-modal');
      this.fnResetChkbox()
    },
    fnCmCuListGetRowById(id){
      let rtnObj = null;
      this.cmCuList.forEach(function(cmCuInfo){
        if(cmCuInfo.cuInfoId == id){
          rtnObj = Object.assign({}, cmCuInfo);
          return false;
        }
      });
      return rtnObj;
    },
    //주소 카테고리 구성원 조회
    fnSearchAddrCatgMem(){
      let params = {
        addressCategoryId: this.searchCategoryId,
        searchTextType: this.searchTextType,
        searchText: (this.searchTextType == 'hpNumber' ? this.searchText.replace(/[^0-9]/gi, '') : this.searchText),
        pageNo: this.pageNo,
        listSize: this.listSize
      };
      MessageApi.selectCmCuList(params).then(response =>{
        const result = response.data;
        this.fnResetChkbox();
        if(result.success) {
          this.cmCuList = Object.assign([], result.data);
          this.totCnt = result.pageInfo.totCnt;

          const vm = this;
          this.cmCuList.forEach(function(cmCuInfo){
            vm.contsVarNms.forEach(function(varNm){
              cmCuInfo[varNm] = '';
            });
          });
        } else {
          this.alertDesc = result.message;
          this.$bvModal.show('alert-modal');
        }
      });
    },
    async fnSearchAddrMem(){
      let limitLength = 0;

      if(this.$gfnCommonUtils.isEmpty(this.searchText) == false){
        if(this.searchTextType == 'hpNumber'){
          limitLength = 4;
        } else {
          limitLength = 2;
        }

        if(this.searchText.length < limitLength){
          this.alertDesc = '검색어를 '+limitLength+'자리 이상 입력해주세요.';
          this.$bvModal.show('alert-modal');
          return;
        }
      }
      this.searchCategoryId = '';
      await this.fnGetAddrList();
      await this.fnAddrCatgMem(this.searchCategoryId);
    },
    //주소 카테고리 클릭
    fnAddrCatgMem(addressCategoryId){
      if(this.$gfnCommonUtils.isEmpty(addressCategoryId)){
        this.cmCuList = [];
        return;
      }
      this.searchCategoryId = addressCategoryId;
      this.fnSearch();
    },
    //주소목록 조회
    async fnGetAddrList(){
      let params = {
        searchTextType: this.searchType,
        searchText: (this.searchTextType == 'hpNumber' ? this.searchText.replace(/[^0-9]/gi, '') : this.searchText)
      };
      await MessageApi.selectAddressList(params).then(response =>{
        const result = response.data;
        if(result.success) {
          this.fnSetAddrListToTree(result.data);
        } else {
          this.alertDesc = result.message;
          this.$bvModal.show('alert-modal');
        }
      });
    },
    //주소목록을 트리구조로 변경
    fnSetAddrListToTree(addrList){
      const vm = this;
      const addrGrpList = Object.assign([], addrList.addrGrpList);
      let addrCtgyList = Object.assign([], addrList.addrCtgyList);
      let addrTreeList = [];
      let addrTreeObj = {};

      //주소록 그룹 put
      addrGrpList.forEach(function(o){
        addrTreeObj = Object.assign({}, o);
        addrTreeObj.addressName = o.addressCategoryGrpName+'('+o.projectName+')'
        addrTreeList.push(Object.assign({}, addrTreeObj));
        if(addrTreeList.length != 0){
          vm.fnSetSubItems(addrCtgyList, addrTreeList[addrTreeList.length-1], 'Y');
        }
      });

      this.addrTreeList = [];
      addrTreeList.forEach(function(addrTreeInfo){
        if(addrTreeInfo.subItems.length != 0){
          addrTreeInfo.isOpen = false;
          vm.addrTreeList.push(addrTreeInfo);
        }
      });
      //this.addrTreeList = Object.assign([], addrTreeList);
    },
    fnSetSubItems(addrCtgyList, target, targetGrpYn){
      const vm = this;
      const tId = target.addressCategoryId;
      const tGrpId = target.addressCategoryGrpId;
      let ctgyInfo;

      if (!('subItems' in target)) target.subItems = [];

      for(let ctgyIdx=0; ctgyIdx<addrCtgyList.length; ctgyIdx++){
        ctgyInfo = addrCtgyList[ctgyIdx];
        if(targetGrpYn == 'Y' && ctgyInfo.parAddressCategoryId == 0){
          if(tGrpId == ctgyInfo.addressCategoryGrpId){
            target.subItems.push(ctgyInfo);
            if(target.subItems.length != 0) vm.fnSetSubItems(addrCtgyList, target.subItems[target.subItems.length - 1], 'N');
          }
        } else {
          if(tId == ctgyInfo.parAddressCategoryId){
            target.subItems.push(ctgyInfo);
            if(target.subItems.length != 0) vm.fnSetSubItems(addrCtgyList, target.subItems[target.subItems.length - 1], 'N');
          }
        }
      }
    },
    //리스트 전체 체크박스
    fnListChkAll(){
      var vm = this;
      if(this.listAllChecked){
        this.cmCuList.forEach(function(cmCuInfo){
          if((vm.requiredCuid && vm.$gfnCommonUtils.isEmpty(cmCuInfo.cuid) == false)
            || (vm.requiredCuPhone && vm.$gfnCommonUtils.isEmpty(cmCuInfo.hpNumber) == false)){
            vm.listChkBox.push(cmCuInfo.cuInfoId);
          }
        });
      } else {
        this.listChkBox = [];
      }
    },
    fnSearch() {
      // this.$refs.pageLayer.fnAllDecrease();
      this.fnSearchAddrCatgMem();
    },
    fnPageClick(pageNo) {
      this.pageNo = pageNo;
      this.fnSearchAddrCatgMem();
    },
    fnResetChkbox(){
      this.listAllChecked = false;
      this.listChkBox = [];
    },
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;

.form-group {
  display: flex;
  margin-bottom: 20px;
  .b-dropdown {
    width: 355px;
    margin-right: 20px;
  }
  .form-control {
    width: 368px;
    margin-right: 20px;
  }
  .btn {
    width: 88px;
  }
}
.card-wrap {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.menu-tree {
  width: 355px;
  height: 350px;
  padding: 20px;
  overflow: auto;
}
.sticky-header {
  width: calc(100% - 355px - 20px);
  max-height: 350px;
  margin-left: 20px;
  overflow-y: auto;
}
.tree-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  & > li {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 11px;
      left: 32px;
      width: 13px;
      height: 1px;
      background-color: var(--gray300);
    }
    &::after {
      content: '';
      position: absolute;
      top: 12px;
      left: 38px;
      width: 1px;
      height: 100%;
      background-color: var(--gray300);
    }
    .collapse {
      padding-left: 38px;
    }
    &:last-child::after {
      display: none;
    }
  }
  .btn {
    border: none;
    line-height: 1;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .btn + span {
    padding-left: 29px;
    @include typography.font-style(14px, 400, 140%, -0.28px);
    color: var(--gray900);
  }
  .menu-list-sub {
    margin: 0;
    padding: 0;
    list-style: none;
    & > li {
      margin-top: 12px;
      margin-left: 21px;
      padding: 0;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 26px;
        height: 1px;
        background-color: var(--gray300);
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: calc(25px + 12px);
        background-color: var(--gray300);
      }
      &:last-child {
        padding-bottom: 12px;
        &::after {
          height: 11px;
        }
      }
      button {
        padding: 0 0 0 35px;
        background-color: var(--white);
        border: none;
        color: var(--gray900);
        &.active {
          color: var(--primary);
        }
      }
    }
  }
}
</style>
