<template>
  <b-modal id="user-info-modal" title="회원 정보" hide-header-close size="md" centered content-class="service-modal">

    <div class="form-row">
      <label>사용자ID</label>
      <p class="m-0 f-body6 c-gray900">{{ loginId }}</p>
    </div>

    <div class="form-row">
      <label>이름</label>
      <div>
        <b-input class="w-100" value='Testadmin' :value="userName" disabled></b-input>
      </div>
    </div>

    <div class="form-row">
      <label>비밀번호</label>
      <div class="d-flex align-items-center">
        <p class="flex-fill m-0 mr-1 f-body6 c-gray900">최종변경일 <span class="pl-2 f-body4 c-gray900">{{ pwdUpdDt }}</span></p>
        <b-button variant="dark" class="ml-2" @click="fnChgPwdDiv">비밀번호 변경</b-button>
      </div>
    </div>

    <div class="form-row" v-if="isPwdChangeVisible">
      <label>비밀번호 변경</label>
      <div class="d-flex">
        <b-input type="password" class="flex-fill w-auto mr-1" v-model="loginPwd" placeholder="대/소문자,숫자,특수문자 조합 (길이8~16자리)" maxlength="16"></b-input>
        <b-input type="password" variant="outline-secondary" v-model="chkLoginPwd" class="ml-2" placeholder="비밀번호 확인" maxlength="16"></b-input>
      </div>
    </div>

    <div class="form-row">
      <label>휴대폰 번호</label>
      <div class="d-flex">
        <b-input class="flex-fill mr-1" value="010-1234-5678" v-model="hpNumber" disabled></b-input>
        <b-button variant="dark" class="ml-2" @click="fnChgHpNumDiv">번호 변경</b-button>
      </div>
    </div>

    <div class="form-row" v-if="isHpChangeVisible">
      <label>휴대폰 번호 변경</label>
      <div class="d-flex">
        <b-input class="flex-fill mr-1" v-model="chgHpNumber" placeholder="-없이 입력" @blur="fnHpNumInit" maxlength="20"></b-input>
        <b-button variant="dark" class="ml-2" @click="fnSetCertifyNumber">인증 요청</b-button>
      </div>
    </div>

    <div class="form-row" v-if="isHpChangeVisible">
      <label>인증번호</label>
      <div>
        <b-input class="w-100" placeholder="인증번호 입력" v-model="certifyNumber"></b-input>
      </div>
    </div>

    <div class="form-row">
      <label>테마 선택</label>
      <div class="color">
        <button type="button" class="color-pick color-pick-blue" :class="{active: color === 'default'}" @click="setColor('default')">
          <IconColorCheck v-if="color === 'default'" color="#111111" />
        </button>
        <button type="button" class="color-pick color-pick-orange" :class="{active: color === 'orange'}" @click="setColor('orange')">
          <IconColorCheck v-if="color === 'orange'" color="#111111" />
        </button>
        <button type="button" class="color-pick color-pick-cyan" :class="{active: color === 'cyan'}" @click="setColor('cyan')">
          <IconColorCheck v-if="color === 'cyan'" color="#111111" />
        </button>
        <button type="button" class="color-pick color-pick-darkblack" :class="{active: color === 'darkblack'}" @click="setColor('darkblack')">
          <IconColorCheck v-if="color === 'darkblack'" color="#111111" />
        </button>
        <button type="button" class="color-pick color-pick-violet" :class="{active: color === 'violet'}" @click="setColor('violet')">
          <IconColorCheck v-if="color === 'violet'" color="#111111" />
        </button>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="outline-primary" @click="closeModal">취소</b-button>
      <b-button variant="primary" @click="fnSave">저장</b-button>
    </template>

    <AlertModal :title="alertTitle" :desc="alertDesc" />
    <PasswordModal />
    <ConfirmModal :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />
  </b-modal>
</template>

<script>
import IconColorCheck from '@/components/service/icons/IconColorCheck.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue';
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue'
import PasswordModal from '@/components/service/user/PasswordModal.vue';
import myPageApi from '@/modules/myPage/service/myPageApi';
import loginApi from '@/modules/login/service/api';

export default {
components: { IconColorCheck, AlertModal, PasswordModal, ConfirmModal, },
name: 'UserInfoModal',
  props: {
    memberInfo : {
      type : Object,
      require : true
    },
    popReset: {
      type: Number
    }
  },
  data() {
    return {
      color: 'color1',
      loginId    : '',
      userName  : '',
      pwdUpdDt  : '',
      hpNumber  : '',
      loginPwd  : '',
      chkLoginPwd : '',
      chgHpNumber : '',
      certifyNumber  : '',
      timeCounter : 0,
      countSpan : false,       // 인증요청 버튼 활성화 문구
      alertTitle: '회원정보',
      alertDesc: '',
      callback: () => { },
      confirmTitle: '회원정보',
      confirmDesc: '',
      theme: '',
      isPwdChangeVisible: false,
      isHpChangeVisible: false,
    }
  },
  watch: {
    popReset() {
      this.fnReset();
    },
    chgHpNumber(newValue, oldValue) {
      // change 이벤트로 발생한 경우에만 실행하도록 조건 추가
      if (newValue !== oldValue) {
        this.chgHpNumber = this.$gfnCommonUtils.hpNumberAddDash(newValue);
      }
    },
  },
  mounted() {
    this.fnReset();
  },
  methods: { 
    closeModal() {
      this.isHpChangeVisible = false;
      this.isPwdChangeVisible = false;
      this.$bvModal.hide('user-info-modal');
    },
    setColor(value) {
      this.color = value
      this.theme = value
    },
    fnChgHpNumDiv() {
      this.isHpChangeVisible = !this.isHpChangeVisible;
    },
    fnChgPwdDiv() {
      this.isPwdChangeVisible = !this.isPwdChangeVisible;
    },
    // 데이터 초기화
    fnReset(){
      this.loginId  = this.memberInfo.loginId;
      this.hpNumber = this.$gfnCommonUtils.hpNumberAddDash(this.memberInfo.hpNumber);
      this.pwdUpdDt = this.memberInfo.pwdUpdDt;
      this.userName = this.memberInfo.userName;
      this.theme = this.memberInfo.theme;
      this.color = this.memberInfo.theme;
      // 변경할 비밀번호, 전화번호 초기화
      this.chgHpNumber  = '';
      this.certifyNumber   = '';
      this.loginPwd     = '';
      this.chkLoginPwd  = '';
      if(this.timeCounter != 0 && this.timeCounter > 0) {
        this.countSpan = true;
      } else {
        this.countSpan = false;
      }
    },
    // 저장
    fnSave(){
      if(jQuery.trim(this.loginPwd).length > 0){
        var numReg = /^(?=.*?[a-z])(?=.*?[0-9]).{10,16}$/;
        var engReg = /^(?=.*?[a-z])(?=.*?[A-Z]).{10,16}$/;
        var speReg = /^(?=.*?[a-z])(?=.*?[?!@#$%^&*+=-_|,.]).{10,16}$/;
        // var speReg = /^(?=.*?[a-z])(?=.*?[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]).{10,}$/;

        var numReg2 = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[?!@#$%^&*+=-_|,.]).{8,16}$/;
        var engReg2 = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[?!@#$%^&*+=-_|,.]).{8,16}$/;
        var speReg2 = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[A-Z]).{8,16}$/;

        if(!numReg.test(this.loginPwd) && !engReg.test(this.loginPwd)&& !speReg.test(this.loginPwd)
          && !numReg2.test(this.loginPwd) && !engReg2.test(this.loginPwd) && !speReg2.test(this.loginPwd)){
            this.alertDesc = '비밀번호는 대/소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 10자리 이상\n또는 3가지 이상을 조합하여 8자리 이상의 길이로 구성해주세요.\n(소문자 필수 입력)';
            this.$bvModal.show('alert-modal');
            return;
        }

        if(this.loginPwd != this.chkLoginPwd){
          this.alertDesc = '변경하려는 비밀번호가 일치하지 않습니다.';
          this.$bvModal.show('alert-modal');
          return;
        }
      }

      if(jQuery.trim(this.chgHpNumber).length > 0){
        if(this.certifyNumber.trim().length == 0){
          this.alertDesc = '인증번호를 입력해주세요.';
          this.$bvModal.show('alert-modal');
          return;
        }
      }

      if(this.userName == this.memberInfo.userName && this.repProjectId == this.memberInfo.repProjectId
      && jQuery.trim(this.loginPwd) == '' && this.chgHpNumber == '' && this.theme == ''){
        this.closeModal();
      } else {
        this.confirmTitle = '회원정보 저장'
        this.confirmDesc = '변경된 회원 정보를 저장 후 로그아웃 처리됩니다.\n저장하시겠습니까?';
        this.callback = this.fnSaveCallBack;
        this.$bvModal.show('confirm-modal');
      }
    },
    fnSaveCallBack(){
      var params = {
        loginPwd : this.loginPwd,
        hpNumber : jQuery.trim(this.$gfnCommonUtils.hpNumberRemoveDash(this.chgHpNumber)),
        certifyNumber : jQuery.trim(this.certifyNumber),
        theme: this.theme,
      }

      myPageApi.saveMemberInfo(params).then(response =>{
        var result = response.data;
        if(result.success) {
          // this.alertDesc = '저장되었습니다.';
          // this.$bvModal.show('alert-modal');
          this.closeModal();
          this.clickLogout();
        } else {
          this.alertDesc = result.message;
          this.$bvModal.show('alert-modal');
          return;
        }
      });
    },
    fnHpNumInit(){
      this.certifyNumber = "";
    },
    clickLogout() {
      loginApi.logout().then(response => {
        if (response.data.success) {
          this.$router.push({path: "/login"});
        }
      });
    },
    start(){ // 1초에 한번씩 start 호출 
      this.polling = setInterval( () =>{
        this.timeCounter--; // 1초씩 감소
        if (this.timeCounter == 0) this.timeStop();
      },1000)
    },
    timeStop() { 
      clearInterval(this.polling);
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/template.scss';
label {
  margin-bottom: 0;
}
.form-row {
  margin: 0;
}
.flex-row-input {
  margin-bottom: 12px;
  input {
    width: calc(50% - 6px);
    & + input {
      margin-left: 12px;
    }
  }
}
.form-row input + .btn,
.form-row p + .btn {
  min-width: 107px;
}
.color {
  display: flex;
  .color-pick {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 5 - 48px);
    min-width: 80px;
    height: 44px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    & + .color-pick {
      margin-left: 12px;
    }
    &-blue {
      background-color: #6D6EFA;
      &.active {
        border: 2px solid #414296;
      }
    }
    &-orange {
      background-color: #f06515;
      &.active {
        border: 2px solid #8C3428;
      }
    }
    &-cyan {
      background-color: #2a6186;
      &.active {
        border: 2px solid #2B0D3B;
      }
    }
    &-darkblack {
      background-color: #1c2435;
      &.active {
        border: 2px solid #193A50;
      }
    }
    &-violet {
      background-color: #471662;
      &.active {
        border: 2px solid #4C5464;
      }
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
}
</style>