import "@/assets/scss/landing/main.scss";
import consoleMain from '../views/bm-consoleMain.vue'
import mainChannel from '../views/bm-mainChannel.vue'
import mainFare from '../views/bm-mainFare.vue'
import mainCustomerGuide from '../views/bm-mainCustomerGuide.vue'
import mainNotice from '../views/bm-mainNotice.vue'
import mainNoticeDetail from '../views/bm-mainNoticeDetail.vue'
import mainFAQ from '../views/bm-mainFAQ.vue'
import mainLibrary from '../views/bm-mainLibrary.vue'
import mainLibraryDetail from '../views/bm-mainLibraryDetail.vue'
import mainQnA from '../views/bm-mainQnA.vue'
import noticePopup from '../components/bp-notice.vue'

export default [
  {
    path: '/public/main',
    name: 'main',
    component: consoleMain,
    meta: { public: true }
  },
  {
    path: '/public/channel',
    name: 'channel',
    component: mainChannel,
    meta: { public: true }
  },
  {
    path: '/public/fare',
    name: 'fare',
    component: mainFare,
    meta: { public: true }
  },
  {
    path: '/public/customer',
    name: 'customer',
    component: mainCustomerGuide,
    meta: { public: true },
    redirect: '/public/customer/notice', // 맨 처음 진입했을시, 공지사항 페이지로 리다이렉트
    children: [
    {
      path: 'notice', // 공지사항 페이지
      name: 'notice',
      component: mainNotice,
      meta: { public: true },
    },
    {
      path: "notice/:noticeId",
      name: "noticeDetail",
      component: mainNoticeDetail,
      meta: { public: true },
      props: true,
    },
    {
      path: 'faq',
      name: 'faq',
      component: mainFAQ,
      meta: { public: true }
    },
    {
      path: 'Library',
      name: 'library',
      component: mainLibrary,
      meta: { public: true },
  },
    {
      path: 'Library/:libraryId',
      name: 'libraryDetail',
      component: mainLibraryDetail,
      meta: { public: true },
      props: true,
  },
    {
      path: 'qna',
      name: 'qna',
      component: mainQnA,
      meta: { public: true }
  },
    ]
  },
  {
    path: '/public/popup/noticePopup',
    name: 'noticePopup',
    component: noticePopup,
    props: true,
    meta: { public: true }
  }
]
