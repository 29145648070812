<template lang="">
  <div class="page-resource-list">
    <h2 class="sr-only">자료실 페이지</h2>
    <div class="filter-area">
      <h3 class="customer-title">자료실</h3>
      
      <div class="search-box">
        <input
            v-model="keyword"
            type="search"
            placeholder="검색어를 입력해주세요"
            class="search-input base-input"
            @keypress.enter="fnSetSelPage()"
          />
          <button 
            type="button" 
            class="btn-search"
            @click="fnSetSelPage()">
            검색
          </button>
        </div>
    </div>
    <div class="resource-list">
      <table class="tbl">
        <thead>
          <tr>
            <th class="mobileHidden">번호</th>
            <th>제목</th>
            <th>첨부파일</th>
            <th class="mobileHidden">작성일</th>
            <th class="mobileHidden">작성자</th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(contant, idx) in contants" :key="idx">
            <td class="num">{{totCnt-offset-contant.rownum+1}}</td>
            <td class="align-left overflow">
              <router-link :to="{ name: 'libraryDetail', params: { libraryId: contant.libraryId }}">
                              {{contant.title}}
              </router-link>
            </td>
            <td>
              <div class="icon-wrap">
                <i v-if="contant.existsFileYn == 'Y'" class="icon-file" alt="파일 아이콘"></i>
              </div>
            </td>
            <td class="date">{{contant.regDt}}</td>
            <td class="writer">운영자</td>
          </tr>
        </tbody>
      </table>
      <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></Pagination>
    </div>
  </div>
</template>
<script>
import Pagination from "../components/MainPagination.vue";
import mainApi from "@/modules/main/service/mainApi.js";

export default {
  name: "library",
  components: { Pagination },

  data() {
    return {
      contants: [],
      keyword: "", // 검색어를 담을 변수
      searchResults: [], // 검색 결과를 담을 변수
      listSize : 10,  // 한페이지 출력 리스트 수
      pageNo : 1,  // 현재 페이징 위치
      totCnt : 0,  //전체 리스트 수
      offset : 0, //페이지 시작점
    }
  },

  mounted() {
    this.fnSelectLibraryList();
    this.fnSetSelPage();
  },

  methods: {
    async goToDetail(libraryId) {
        // 자료실 상세 페이지로 이동
        this.$router.push({ name: 'libraryDetail', params: { libraryId: libraryId } });
      },

      fnSetSelPage(){
			// 검색 조회시 1페이지로 이동
			this.pageNo = 1;
			this.fnSelectLibraryList();
		},
    
    async fnSelectLibraryList(){
      const params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        listSize:  this.listSize
      };

      await mainApi.selectLibraryList(params).then(response =>{
        const result = response.data;
        if(result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    fnSearch(pageNo) {
      this.pageNo = pageNo;
      this.fnSelectLibraryList();
    },
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as f;

.page-resource-list {
  padding-top: 60px;

  @include f.tablet {
    padding-top: 100px;
  }

  .filter-area {
    display: flex;
    flex-direction: column-reverse;

    @include f.tablet {
      @include f.flex(row, space-between, center);
    }
    .customer-title {
      padding-top: 40px;
    }

  .search-box {
  @include f.flex(row, null, center);
  .search-input {
    position: relative;
    width: 190px;
    margin-right: 8px;
    background: url(f.$icon + "ico-search.png") no-repeat center;
    background-position-x: calc(100% - 12px);
    @include f.tablet {
      width: 300px;
      margin-right: 12px;
      background-position-x: calc(100% - 16px);
    }
  }

  .btn-search {
    width: 56px;
    height: 35px;
    border-radius: 6px;
    background: f.color(gray600);
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    line-height: 35px;
    @include f.tablet {
      width: 120px;
      height: 44px;
    }
  }
}
  }

  .resource-list {
    table {
      thead {
        tr {
          th {
            &:first-child {
              width: 10%;
            }

            &:nth-child(2) {
              width: 40%;
            }

            &:nth-child(3) {
              width: 20%;
            }

            &:nth-child(4) {
              width: 20%;
            }

            &:last-child {
              width: 10%;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &.num {
              @include f.tablet {
                font-size: 1.125rem;
              }
            }

            &.overflow {
              padding-left: 12px;
              font-size: 0.875rem;

              @include f.tablet {
                font-size: 1rem;
              }

              @include f.desktop {
                padding-left: 40px;
              }
            }

            .icon-wrap {
              @include f.flex-center;

              .icon-file {
                position: relative;
                display: inline-block;
                width: 16px;
                height: 16px;

                @include f.tablet {
                  width: 24px;
                  height: 24px;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 16px;
                  height: 16px;
                  background: url(f.$icon + "ico-file.png") no-repeat;
                  background-size: 100%;

                  @include f.tablet {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 350px) {
  .page-resource-list .filter-area .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

  .search-box {
    @include f.flex(row, null, center);
    .search-input {
      position: relative;
      width: 210px !important;
      margin-right: 8px;
      background: url(f.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include f.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
  }

  .btn-search {
      width: 56px;
      height: 35px;
      border-radius: 6px;
      background: f.color(gray600);
      font-weight: 400;
      font-size: 0.875rem;
      color: #fff;
      line-height: 35px;
      @include f.tablet {
        width: 120px;
        height: 44px;
      }
    }
  }
}

/* 예외 */
@media (max-width: 375px) {
  .page-resource-list .filter-area .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

  .mobileHidden, .num, .date, .writer {
      display: none;
    }
  .page-notice-list .notice-list table tbody tr td.overflow {
    padding-left: 0px;
  }
  .search-box {
    @include f.flex(row, null, center);
    .search-input {
      position: relative;
      width: 220px !important;
      margin-right: 8px;
      background: url(f.$icon + "ico-search.png") no-repeat center;
      background-position-x: calc(100% - 12px);
      @include f.tablet {
        width: 300px;
        margin-right: 12px;
        background-position-x: calc(100% - 16px);
      }
    }
  }
}
</style>
