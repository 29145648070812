<template>
  <div class="uc-message-send-sms">
    <MessageTabs />

    <div class="d-flex tab-contents">
      <div class="card">
        <div class="preview p-sticky">
          <p class="preview-title">미리보기</p>
          <PreviewTalk size="lg">
            <div class="w-100">
              <div v-if="sendData.emphasizeType == 'TEXT'">
                <p v-if="!$gfnCommonUtils.isEmpty(sendData.tmpltEmpsSubTitle)">{{sendData.tmpltEmpsSubTitle}}</p>
                <p v-if="!$gfnCommonUtils.isEmpty(sendData.tmpltEmpsTitle)">{{sendData.tmpltEmpsTitle}}</p>
              </div>
              <div v-if="sendData.emphasizeType == 'IMAGE'">
                <div v-if="sendData.templateImageUrl != ''" 
                    :style="{
                      padding: '48px',
                      borderRadius: '0px',
                      backgroundImage: 'url(' + sendData.templateImageUrl + ')',
                      backgroundSize: 'cover',  // 이미지가 컨테이너 크기에 맞춰 자동 크기 조정
                      backgroundPosition: 'center',  // 이미지가 중앙에 위치하도록 설정
                      backgroundRepeat: 'no-repeat',  // 이미지 반복하지 않도록 설정
                      height: '100%',  // 부모 컨테이너의 높이에 맞추기
                      width: '100%'   // 부모 컨테이너의 너비에 맞추기
                    }">
                </div>
              </div>
              <p class="f-body6">{{sendData.tmpltContent}}</p>
              <div v-for="(buttonInfo, idx) in sendData.buttonList" :key="idx" style="margin-bottom: 5px;">
                <b-button 
                  v-if="!$gfnCommonUtils.isEmpty(buttonInfo.name)" 
                  variant="secondary" 
                  class="w-100">
                  {{ buttonInfo.name }}
                </b-button>
              </div>        
            </div>
          </PreviewTalk>
        </div>
      </div>
      <div class="section card flex-fill">
        <p class="section-title f-body1 c-gray700">01.발송정보</p>
        <div class="d-flex align-items-center">
          <label class="form-label">템플릿</label>
          <b-button variant="outline-primary" size="sm" v-b-modal.select-talk-template-modal>템플릿 선택</b-button>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">02.메시지 내용</p>
        <div class="d-flex align-items-center">
          <label class="form-label">내용</label>
          <b-button variant="outline-primary" size="sm" v-b-modal.view-content-modal>내용보기</b-button>
        </div>
        <div class="d-flex button-list">
          <label class="form-label">버튼</label>
          <div class="table-responsive">
            <table class="table">
              <colgroup>
                <col width="25%">
                <col width="25%">
                <col width="40%">
                <col width="10%">
              </colgroup>
              <thead>
                <tr>
                  <th>
                    타입
                    <!-- <b-button v-b-tooltip.hover.html="msgTooltip" variant="light" class="btn-icon p-0 btn-tooltip"> -->
                    <b-button variant="light" class="btn-icon p-0 btn-tooltip">
                      <IconInfo />
                    </b-button>
                  </th>
                  <th>버튼 이름</th>
                  <th>버튼 링크</th>
                </tr>
              </thead>  
              <tbody>
                <tr v-for="(buttonInfo, idx) in sendData.buttonList" :key="idx">
                  <td>
                    <b-input disabled :value="buttonInfo.linkType"></b-input>
                  </td>
                  <td>
                    <b-input disabled :value="buttonInfo.name"></b-input>
                  </td>
                  <td>
                    <span class="f-body5 c-gray400" v-if="buttonInfo.linkMo">{{ buttonInfo.linkMo }}</span>
                    <span class="f-body5 c-gray400" v-if="buttonInfo.linkMo && buttonInfo.linkPc"> | </span>
                    <span class="f-body5 c-gray400" v-if="buttonInfo.linkPc">{{ buttonInfo.linkPc }}</span>
                    <span class="f-body5 c-gray400" v-if="(buttonInfo.linkMo || buttonInfo.linkPc) && buttonInfo.linkAnd"> | </span>
                    <span class="f-body5 c-gray400" v-if="buttonInfo.linkAnd">{{ buttonInfo.linkAnd }}</span>
                    <span class="f-body5 c-gray400" v-if="(buttonInfo.linkMo || buttonInfo.linkPc || buttonInfo.linkAnd) && buttonInfo.linkIos"> | </span>
                    <span class="f-body5 c-gray400" v-if="buttonInfo.linkIos">{{ buttonInfo.linkIos }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">03.수신자 선택</p>
        <div class="d-flex align-items-center">
          <label class="form-label">수신자<span class="require">*</span></label>
          <div class="receive-btn-wrap">
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" id="DICT" name="cuInputType" value="DICT" v-model="sendData.cuInputType" @change="fnChgCuInputType()" @click="fnClickCuInputType($event)" activity="READ" v-b-modal.enter-receiver-modal>
              <span>수신자 직접입력</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" id="ADDR" name="cuInputType" value="ADDR" v-model="sendData.cuInputType" @change="fnChgCuInputType()" @click="fnClickCuInputType($event)" activity="READ" v-b-modal.search-address-modal>
              <span>주소록 검색</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" id="EXCEL" name="cuInputType" value="EXCEL" v-model="sendData.cuInputType" @change="fnChgCuInputType()" @click="fnClickCuInputType($event)" activity="READ"> <!--@click="triggerFileInput"-->
              <span value="EXCEL">엑셀 업로드</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="light" class="btn btn-sm btn-svg btn-svg-right btn-sample-download" @click="fnExcelTmplteDownLoad()">
              <span>샘플 다운로드</span>
              <IconDownload />
            </b-button>

            <input ref="excelFile" type="file" style="display:none;" @change="fnReadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
          </div>
        </div>

        <div class="d-flex align-items-center receive-count">
          <p class="m-0 mr-1 pr-3 f-body5 c-gray600">수신자: <span class="text-primary">{{ recvCnt }}명</span></p>
          <!-- <b-button variant="outline-secondary" size="sm" :disabled="sendData.senderType === 'SMS'">수신자 모두삭제</b-button> -->
          <b-button variant="outline-secondary" size="sm" @click="removeItemList()">수신자 모두삭제</b-button>
        </div>
        <div class="receive-table">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>매체</th>
                  <th>번호</th>
                  <th>변수</th>
                  <th class="text-center">삭제</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cuInfo, index) in sendData.cuInfo" :key="index">
                  <td>phone</td>
                  <td>{{cuInfo.phone}}</td>
                  <td>
                    {{ cuInfo.mergeData && Object.keys(cuInfo.mergeData).length > 0 ? cuInfo.mergeData : '' }}
                  </td>
                  <td class="text-center">
                    <button class="btn btn-icon p-0" @click="removeItem(index)">
                      <IconClose />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">04.발송옵션 선택</p>
        <div class="d-flex align-items-center">
          <label class="form-label">발송시간<span class="require">*</span></label>
          <b-form-group class="m-0">
            <b-form-radio-group inline v-model="sendData.rsrvSendYn">
              <b-form-radio name="time" value="N">즉시</b-form-radio>
              <b-form-radio name="time" value="Y">예약</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div v-if="sendData.rsrvSendYn === 'Y'" class="book-form">
            <CustomDatepicker v-model="sendData.rsrvDate" @input="fnUpdateRsrvDate"/>
            <vue-timepicker v-model="sendData.rsrvHH" format="HH" class="book-time" close-on-complete></vue-timepicker>
            <span class="time-divider">:</span>
            <vue-timepicker v-model="sendData.rsrvMM" format="mm" class="book-time" close-on-complete></vue-timepicker>
          </div>
        </div>

        <hr class="hr">

        <div class="submit-wrap">
          <b-button variant="secondary" size="lg" v-b-modal.send-test-modal>테스트 발송</b-button>
          <b-button variant="primary" size="lg" @click="fnSendAlimTalkMessage('N')">발송</b-button>
        </div>
      </div>
    </div>

    <SelectTalkTemplateModal @select-template="fnSetTemplateInfo" :propSenderKey="sendData.senderKey" :alimTalkTemplateOpen="alimTalkTemplateOpen" ref="alimTalkTmplPopup"/>
    <ViewContentModal :tmpltContent="sendData.tmpltContent"/>
    <SearchAddressModal v-model:isModalVisible="isModalVisible" :contsVarNms="sendData.contsVarNms" :requiredCuPhone="sendData.requiredCuPhone" :requiredCuid="sendData.requiredCuid" />
    <EnterReceiverModal :directInputOpen="directInputOpen" :contsVarNms="sendData.contsVarNms" :requiredCuPhone="sendData.requiredCuPhone" :requiredCuid="sendData.requiredCuid" :recvInfoLst="sendData.recvInfoLst"/>

    <SendTestModal :testSendInputOpen="testSendInputOpen" :contsVarNms="sendData.contsVarNms" :requiredCuPhone="sendData.requiredCuPhone" :requiredCuid="sendData.requiredCuid" ref="testSendInputPopup"/>
    <AlertModal :title="alertTitle" :desc="alertDesc" />
    
  </div>
</template>
<script>
import IconInfo from '@/components/service/icons/IconInfo.vue'
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import IconDownload from '@/components/service/icons/IconDownload.vue';
import SearchAddressModal from '@/components/service/modal/SearchAddressModal.vue';
import EnterReceiverModal from '@/modules/message/components/modal/EnterReceiverModal.vue';
import IconClose from '@/components/service/icons/IconClose.vue';
import CustomDatepicker from '@/components/service/form/CustomDatepicker.vue'
import SelectTalkTemplateModal from '@/modules/message/components/modal/SelectTalkTemplateModal.vue';
import ViewContentModal from '@/modules/message/components/modal/ViewContentModal.vue';
import PreviewTalk from '@/components/service/preview/PreviewTalk.vue';
import MessageTabs from '@/components/service/message/MessageTabs.vue'
import SendTestModal from '@/modules/message/components/modal/SendTestModal.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue';
import messageApi from "@/modules/message/service/messageApi.js";
import templateApi from "@/modules/template/service/templateApi.js";
import XLSX from 'xlsx';

export default {
  components: { SendTestModal, IconInfo, IconArrowRight, IconDownload, IconClose, SearchAddressModal, EnterReceiverModal, CustomDatepicker, SelectTalkTemplateModal, PreviewTalk, MessageTabs, ViewContentModal, AlertModal, },
  name: "ucRcsTemplateSend",
  data() {
    return {
      subTab: 'allow',
      templateTab: 'template',
      barnd: '',
      phoneNumber: '',
      time: 'now',
      templateCard: 'A',
      startTime: null,
      endTime: null,
      isVisible: false,
      templateSet: false,
      alimTalkTemplateOpen: false,
      alimTalkContsOpen: false,
      rplcSendOpen: false,
      addressInputOpen: false,
      testSendInputOpen: false,
      recvCnt : 0,  //수신자명수
      inProgress: false,
      previewMessageType: 'ALIMTALK',  //ALIMTALK, RPLC
      buttonLinkTypeList : [
        {linkType:'DS', name:'배송 조회'},
        {linkType:'WL', name:'웹 링크'},
        {linkType:'AL', name:'앱 링크'},
        {linkType:'BK', name:'봇 키워드'},
        {linkType:'MD', name:'메시지 전달'},
        {linkType:'BC', name:'상담톡 전환'},
        {linkType:'BT', name:'봇 전환'},
        //{linkType:'AC', name:'채널 추가'},  //광고 추가/복합형만
      ],
      buttonDSDescription : '카카오 메세지에 택배사 명과 송장번호를 기재한 후, 배송 조회 버튼을 추가하시면 메세지에서 택배사 명과 송장번호를 추출하여 배송 조회 카카오 검색페이지 링크가 자동으로 생성됩니다. 카카오에서 지원하는 택배사명과 운송장번호가 알림톡 메시지 내에 포함된 경우에만 배송조회 버튼이 표시됩니다. 배송 조회가 가능한 택배사는 <span style="color:#e11d21"><strong>카카오와 해당 택배사와의 계약 관계에 의해 변동될 수 있음을 유의해주시기 바랍니다.</strong></span>',
      tempFile: [],
      beforeCuInputType: 'DICT',
      monthAmount : 0,
      monSenderLimitAmout : '없음',
      feeType : false,
      senderKeyType: 'S',
      senderKeyList: [],
      sendData : {
        kkoChId:"",
        chGrp: 'KKO',
        ch: 'ALIMTALK',
        requiredCuid : false,
        requiredCuPhone : true,
        msgKind: 'I',  //알림톡은 광고성을 보내지 않는다.
        rplcSendType: 'NONE',  //NONE, SMS, LMS, MMS
        cuInputType:'',  //DICT, ADDR, EXCEL
        rsrvSendYn: 'N',  //예약발송여부
        rsrvDate: this.$gfnCommonUtils.getCurretDate(),
        rsrvHH: '00',
        rsrvMM: '00',
        campaignId: '',
        cuInfo: '',
        senderKeyType : '',
        senderKey : '',
        tmpltCode : '',
        tmpltKey : '',
        emphasizeType: '',
        tmpltEmpsTitle: '',
        tmpltEmpsSubTitle: '',
        templateImageUrl : '',
        tmpltContent: '',
        contsVarNms: [], //메세지 내용 변수명
        buttonList : [],
        fbInfo: {},  //대체발송정보
        recvInfoLst: [],  //수신자정보
        testRecvInfoLst: [],  //테스트 수신자정보
        excelLimitRow: 0
      },
      nightSendSthh: '',
      nightSendStmm: '',
      nightSendEdhh: '',
      nightSendEdmm: '',
      nightSendYn : 'N',
      nightSendLimitYn : false,
      alertTitle: '알림톡 발송',
      alertDesc: '',
      isModalVisible: false,
      directInputOpen : false,
    }
  },
  watch : {
    recvCnt (newval) {
      if(newval>30000) {
        this.alertDesc = "발송 최대 수신자 수는 30000명을 넘길 수 없습니다.";
        this.$bvModal.show('alert-modal');
        this.fnRemoveRecvInfo();
      }
    }
  },
  async mounted() {
    await this.fnExistApiKey();   //api키 확인
    await this.fnValidUseChGrp(); //채널확인
    await this.fnGetSenderKeyList(); //카카오 발신 프로필키 일반 목록 조회  (카카오채널 리스트)
    await this.fnNightSendTime(); //야간 메세지 확인
  },
  methods: {
    changeSubTab(value) {
      this.subTab = value;
    },
    changeTemplateTab(value) {
      this.templateTab = value;
    },
    tooltipContent() {
      return '<ul><li>정보성 메시지를 사전에 등록 및 승일을 받아 메시지를 발송할 수 있습니다.</li><li>스타일/서술 템플릿 형식은 승인완료된 양식만 발송 할 수 있습니다.</li><li>템플릿형은 광고성 메시지를 발송할 수 없습니다.</li></ul>'
    },
    setBrand(value) {
      this.barnd = value;
    },
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    showConfirmModal() {
      this.$bvModal.show('confirm-modal');
    },
    triggerFileInput() {
      // 파일 입력 요소 클릭
      this.$refs.fileInput.$el.click();
    },
    fnRemoveRecvInfo(){
      this.fnCallbackRecvInfoLst(null);
      this.$refs.excelFile.value = '';
      this.tempFile = [];
    },
    fnReadFile(){
      if(this.$refs.excelFile.files && this.$refs.excelFile.files.length > 0){
        const file = this.$refs.excelFile.files[0];
        let reader = new FileReader();

        reader.onload = () => {
          let data = reader.result;
          let workbook = XLSX.read(data, {type: 'binary'});
          let sheetName = '';
          let excelArray = [];

          if(workbook.SheetNames && workbook.SheetNames.length > 0){
            sheetName = workbook.SheetNames[0];
          }
          if(this.$gfnCommonUtils.isEmpty(sheetName) == false){
            excelArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          }

          let recvArr = [];

          for(var i=0; i<excelArray.length; i++) {
            if(i!=(excelArray.length-1)) recvArr[i] = excelArray[i+1].Template;
          }

          recvArr = new Set(recvArr);
          this.sendData.excelLimitRow = (excelArray.length-1);
          this.recvCnt = recvArr.size;
        };
        reader.readAsBinaryString(file);
      } else {
        this.recvCnt = 0;
      }
    },
    fnReset(){
      Object.assign(this.$data, this.$options.data.apply(this));
      this.fnGetSenderKeyList();
      this.fnNightSendTime();
    },
    async fnExistApiKey(){
      let params = {};
      await messageApi.selectApiKey(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            this.alertDesc = '해당 프로젝트의 사용가능한 API 키가 존재하지 않습니다.\n메시지 발송하실 수 없습니다.'; 
            this.$bvModal.show('alert-modal');
          } else {
            // 사용가능한 api 키가 존재하면 발송제한 금액 세팅
            this.fnSetSentAmount();
          }
        }
      });
    },
    async fnValidUseChGrp(){
      let params = {chGrp: this.sendData.chGrp};
      await messageApi.selectValidUseChGrp(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            this.alertDesc = '이용하실 수 없는 채널입니다.'; 
            this.$bvModal.show('alert-modal');
            this.$router.back();
          }
        } else {
          this.alertDesc = '시스템 오류입니다. 잠시 후 다시 시도하세요.'; 
          this.$bvModal.show('alert-modal');
          this.$router.back();
        }
      });
    },
    fnSetSentAmount() {
      let params = {};
      var vm = this;
      messageApi.setSentAmout(params).then(response =>{
        const result = response.data;
        if(result.success) {
          let resultData = result.data;
          vm.monthAmount = resultData.amountMap.month + "원";
          vm.monSenderLimitAmout = resultData.returnApiKeyMap.monSenderLimitAmount+"원";

          if(resultData.returnApiKeyMap.feeType == "PRE") {
            vm.feeType = false;
          } else {
            vm.feeType = true;
          }
        }
      });
    },
    //메세지 미리보기 변경
    fnChgPreviewMessageType(type){
      this.previewMessageType = type;
    },
    fnOpenTestSendInputPopup(){
      this.fnSetContsVarNms();
      this.$refs.testSendInputPopup.fnSetTestRecvInfoLst(this.sendData.testRecvInfoLst);
      this.testSendInputOpen = !this.testSendInputOpen;
    },
    //테스트 발송 callback
    fnCallbackTestRecvInfoLst(testRecvInfoLst){
      if(testRecvInfoLst != null){
        this.sendData.testRecvInfoLst = Object.assign([], testRecvInfoLst);
        this.fnSendAlimTalkMessage('Y');
      } else {
        this.sendData.testRecvInfoLst = [];
      }
    },
    //발송 정보 유효성 체크
    fnValidSendMsgData(testSendYn){
      if(this.fnSetContsVarNms() == false){
        return false;
      }
      if(!this.sendData.senderKeyType
        //|| !this.sendData.senderKey
        || !this.sendData.tmpltCode
        || !this.sendData.tmpltContent){
        this.alertDesc = '템플릿을 선택해주세요.'; 
        this.$bvModal.show('alert-modal');
        return false;
      }
      if(testSendYn == 'Y'){
        if(!this.sendData.testRecvInfoLst == null || this.sendData.testRecvInfoLst.length == 0){
          this.alertDesc = '테스트 수신자 정보를 입력해주세요.'; 
          this.$bvModal.show('alert-modal');
          return false;
        }
      } else {
        if(this.sendData.cuInputType == 'DICT' || this.sendData.cuInputType == 'ADDR'){
          if(!this.sendData.recvInfoLst == null || this.sendData.recvInfoLst.length == 0){
            this.alertDesc = '수신자 정보를 입력해주세요.'; 
            this.$bvModal.show('alert-modal');
            return false;
          }
        }
        if(this.sendData.cuInputType == 'EXCEL'){
          if(this.$refs.excelFile.value != 0){
            this.tempFile = [];
            this.tempFile.push.apply(this.tempFile, this.$refs.excelFile.files);
          }
          const uploadFile = this.tempFile[0];
          if(!uploadFile){
            this.alertDesc = '엑셀파일을 등록해주세요.'; 
            this.$bvModal.show('alert-modal');
            return false;
          }
          const permitExten = 'xls,xlsx'.split(',');
          const extnIdx = uploadFile.name.lastIndexOf('.');
          const extn = uploadFile.name.substring(extnIdx+1);
          if((permitExten.indexOf(extn) < 0)){
            this.alertDesc = '허용되지 않는 확장자입니다.'; 
            this.$bvModal.show('alert-modal');
            return false;
          }
        }
        if(this.sendData.rplcSendType != 'NONE'){
          if(!this.sendData.fbInfo.callback){
            this.alertDesc = '대체발송시 대체발송 발신번호를 입력해주세요'; 
            this.$bvModal.show('alert-modal');
            return false;
          }
          if(!this.sendData.fbInfo.msg){
            this.alertDesc = '대체발송시 대체발송 내용을 입력해주세요.'; 
            this.$bvModal.show('alert-modal');
            return false;
          }
        }
      }
      return true;
    },
    //메시지 발송 처리
    async fnSendAlimTalkMessage(testSendYn){
      if(this.inProgress){
        this.alertDesc = '알림톡 메시지 발송 처리중입니다.'; 
        this.$bvModal.show('alert-modal');
        return;
      }

      //유효성 체크
      if(this.fnValidSendMsgData(testSendYn) == false) return;

      if(testSendYn != 'Y' && this.fnNightSendCheck() == false) return;

      //발송처리
      let params = Object.assign({}, this.sendData);
      params.testSendYn = testSendYn;

      if(testSendYn == 'Y'){
        params.recvInfoLst = Object.assign([], this.sendData.testRecvInfoLst);
        params.cuInputType = 'DICT';
        //테스트 발송은 대체발송 하지 않는다.
        params.rplcSendType = 'NONE'
        params.fbInfo = {};
        //테스트 발송은 즉시발송만 가능
        params.rsrvSendYn = 'N';
      }

      let fd = new FormData();
      if(this.sendData.cuInputType == 'EXCEL'){
        fd.append('file', this.tempFile[0]);
        this.$refs.excelFile.value = '';
        this.tempFile = [];
      }
      fd.append('paramString', JSON.stringify(params));

      this.inProgress = true;
      const vm = this;

      await messageApi.sendAlimTalkMessage(fd).then(response =>{
        this.inProgress = false;
        const result = response.data;

        if(result.success) {
          if(testSendYn == 'Y'){
            if(!this.$gfnCommonUtils.isEmpty(result.message)){
              this.alertDesc = result.message;
              this.$bvModal.show('alert-modal');
            } else {
              this.alertDesc = '발송하였습니다'; 
              this.$bvModal.show('alert-modal');
            }
          } else {
            if(result.data != null && !this.$gfnCommonUtils.isEmpty(result.data.feeMsg)){
              eventBus.$on('callbackEventBus', this.fnAlertFeeMsgCallBack);
              this.alertDesc = result.data.feeMsg; 
              this.$bvModal.show('alert-modal');
            } else {
              this.fnAlertFeeMsgCallBack(result);
            }
          }
        } else {
          this.alertDesc = result.message; 
          this.$bvModal.show('alert-modal');
        }
      })
      .catch(function () {
        vm.inProgress = false;
      });

      // 발송 제한 금액 세팅
      this.fnSetSentAmount();
    },
    fnAlertFeeMsgCallBack(result){
      if(this.$gfnCommonUtils.isEmpty(result.message)){
        this.alertDesc = '발송 요청 처리 되었습니다.'; 
        this.$bvModal.show('alert-modal');
      } else {
        this.alertDesc = result.message; 
        this.$bvModal.show('alert-modal');
      }
      //this.fnReset();
    },
    fnUpdateRsrvDate(sltDate){
      this.sendData.rsrvDate = sltDate;
    },
    fnClickCuInputType(e){
      this.sendData.cuInputType = e.currentTarget.id;
      this.tempFile = [];
      this.tempFile.push.apply(this.tempFile, this.$refs.excelFile.files);
      this.$refs.excelFile.value = '';
      if(this.sendData.cuInputType == e.currentTarget.id){
        this.fnChgCuInputType('N');
      }
      this.beforeCuInputType = this.sendData.cuInputType;
    },
    //수신자 입력 타입 변경시
    fnChgCuInputType(chgYn){
      if(this.$gfnCommonUtils.defaultIfEmpty(chgYn, 'Y') == 'Y'){
        if((this.beforeCuInputType != 'DICT' && this.beforeCuInputType != 'ADDR')
          || (this.sendData.cuInputType != 'DICT' && this.sendData.cuInputType != 'ADDR')){
          this.fnCallbackRecvInfoLst(null);  //수신자 입력 타입 변경시 수신자 정보 초기화
        }
      }
      if(!this.sendData.tmpltContent){
        this.alertDesc = '템플릿을 먼저 선택해주세요.'; 
        this.$bvModal.show('alert-modal');
        return;
      }
      if(this.fnSetContsVarNms() == false){
        return;
      }

      switch (this.sendData.cuInputType) {
        case 'DICT':
          // 수신자 직접입력 팝업 호출
          this.directInputOpen = true;
          break;

        case 'ADDR':
          // 주소록 검색 팝업 호출
          this.isModalVisible = true;
          break;

        case 'EXCEL':
          // 엑셀파일찾기 호출
          this.$refs.excelFile.click();
          break;

        default:
          console.warn('알 수 없는 입력 타입:', inputType);
          break;
      }
    },
    fnSetContsVarNms(){
      const rsvNmSet = new Set(['phone']);
      const conts = this.sendData.tmpltContent + (typeof this.sendData.fbInfo.msg === 'undefined' ? '' : this.sendData.fbInfo.msg);
      let varNms = [];
      let containRsvNm = false;
      conts.replace(/#\{(([a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|_])+)\}/g, function($0, $1) {
        if(rsvNmSet.has($1)){
          containRsvNm = true;
          return false;
        }
        varNms.push($1);
      });
      if(containRsvNm){
        this.alertDesc = '발송 내용 변수 phone 은 예약어로 사용하실 수 없습니다.'; 
        this.$bvModal.show('alert-modal');
        return false;
      } else {
        this.sendData.contsVarNms = this.fnSetArrayRemoveDupliVal(varNms);
        return true;
      }
    },
    //array에 중복 항목을 제거한다.
    fnSetArrayRemoveDupliVal(array){
      let seen = {};
      return array.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
      });
    },
    fnGetButtonNm(linkType){
      let buttonNm = '';
      this.buttonLinkTypeList.forEach(function(buttonInfo){
        if(buttonInfo.linkType == linkType){
          buttonNm = buttonInfo.name;
          return false;
        }
      });
      return buttonNm;
    },
    fnOpenReplacedSenderPopup(){
      this.sendData.fbInfo.ch = this.sendData.rplcSendType;
      this.$refs.rplcSendPopup.fnSetfbInfo(this.sendData);
      this.rplcSendOpen = !this.rplcSendOpen;
    },
    //대체발송 정보 callback
    fnCallbackFbInfo(fbInfo) {
      this.sendData.fbInfo = fbInfo;
      this.fnCallbackRecvInfoLst(null);  //수신자 정보 초기화
    },
    //대체발송 타입 변경시
    fnChgRplcSendType(){
      this.sendData.fbInfo = {};  //대체발송 정보 초기화
      this.fnCallbackRecvInfoLst(null);  //수신자 정보 초기화
      //this.$refs.cuInputType_ALL.click();
      if(this.sendData.rplcSendType == 'NONE'){
        this.previewMessageType = 'ALIMTALK';
      }
    },
    //템플릿 정보 Set
    fnSetTemplateInfo(templateInfo){
      this.templateSet = true;
      Object.assign(this.sendData, templateInfo);
      this.sendData.kkoChId = templateInfo.kkoChId;
      this.isVisible = true;
    },
    fnOpenAlimTalkContentsPopup(){
      this.alimTalkContsOpen = true;
    },
    fnOpenAlimTalkTemplatePopup(){
      // let senderKey = this.sendData.senderKey;
      // if(senderKey == "") {
      //   confirm.fnAlert(this.componentsTitle, "카카오 채널을 선택해 주세요.");
      //   return;
      // }
      this.$refs.alimTalkTmplPopup.fnSelectSenderKeyList();
      this.$refs.alimTalkTmplPopup.fnAlimTalkPopOpen();
      this.alimTalkTemplateOpen = true;
    },
    //빈오브젝트확인
    fnIsEmptyObj(obj){
      if(typeof obj === 'undefined') return true;
      if(Object.keys(obj).length === 0) return true;
      return false;
    },
    //수신자 정보 callback
    fnCallbackRecvInfoLst(recvInfoLst, addYn) {
      if(recvInfoLst != null){
        if(this.$gfnCommonUtils.defaultIfEmpty(addYn, 'N') == 'Y'){
          this.sendData.recvInfoLst = this.sendData.recvInfoLst.concat(recvInfoLst);
        } else {
          this.sendData.recvInfoLst = recvInfoLst;
        }
        //수신자 중복제거
        this.fnDelDuplRecvInfo();

        this.recvCnt = this.sendData.recvInfoLst.length;
        this.sendData.cuInfo = this.sendData.recvInfoLst;
      } else {
        this.recvCnt = 0;
        this.sendData.recvInfoLst = [];
        this.sendData.cuInfo = '';
      }
    },
    //수신자 중복 제거
    fnDelDuplRecvInfo(){
      const vm = this;
      this.sendData.recvInfoLst = this.sendData.recvInfoLst.filter(function(item, i){
        return (
          vm.sendData.recvInfoLst.findIndex((item2) => {
            return item.phone === item2.phone
          }) === i
        );
      });
    },
    async fnExcelTmplteDownLoad(){
      if(this.fnSetContsVarNms() == false) return;
      var params = {
        contsVarNms : this.sendData.contsVarNms,
        requiredCuid: this.sendData.requiredCuid,
        requiredCuPhone: this.sendData.requiredCuPhone
      };
      await messageApi.excelDownSendAlimTalkRecvTmplt(params);
    },
    fnGetSenderKeyList(){  //카카오톡 발신 프로필키 리스트 조회 (카카오채널 리스트, 메인 화면)
      const params = {kkoSvc: this.sendData.ch, senderKeyType: this.senderKeyType};
      templateApi.selectSenderKeyList(params).then(response => {
        var result = response.data;
        if(result.success) {
          this.senderKeyList = result.data;
        } else {
          this.alertDesc = result.message; 
          this.$bvModal.show('alert-modal');
        }
      });
    },
    //야간 메시지 전송 체크
    fnNightSendCheck(){
      let params = {
        nightSendYn : this.nightSendYn,
        rsrvSendYn : this.sendData.rsrvSendYn,
        rsrvHH : this.sendData.rsrvHH,
        rsrvMM : this.sendData.rsrvMM,
        nightSendSthh : this.nightSendSthh,
        nightSendStmm : this.nightSendStmm,
        nightSendEdhh : this.nightSendEdhh,
        nightSendEdmm : this.nightSendEdmm
      }
      var nightSendLimitYn = messageApi.checkNightSendTime(params);

      if(nightSendLimitYn){
        this.nightSendLimitYn = nightSendLimitYn;
      }

      return !nightSendLimitYn;
    },
    removeItemList() {
      this.recvCnt = 0;
      this.sendData.recvInfoLst = [];
      this.sendData.cuInfo = [];
    },
    removeItem(index) {
      this.recvCnt = this.recvCnt - 1;
      this.sendData.recvInfoLst.splice(index, 1);
      this.sendData.cuInfo.splice(index, 1);
    },
    // 야간 메시지 전송 시간 확인
		async fnNightSendTime() {
			let params = {
        isChk : "Y"
      };
			await messageApi.selectNightSendTime(params).then(response =>{
				var result = response.data;
				if(result.success) {
					this.nightSendSthh = result.data.nightSendSthh;
					this.nightSendStmm = result.data.nightSendStmm;
					this.nightSendEdhh = result.data.nightSendEdhh;
					this.nightSendEdmm = result.data.nightSendEdmm;
          this.nightSendYn = result.data.nightSendYn;
				} else {
					confirm.fnAlert(this.title, result.message);
          this.alertDesc = result.message; 
          this.$bvModal.show('alert-modal');
				}
			});
		},
  },
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/message.scss';

.basic-input {
  max-width: 348px;
}
.custom-radio {
  margin-right: 20px;
}
.receive-btn-wrap {
  button + button {
    margin-left: 12px;
  }
}
.button-list {
  margin-top: 20px;
  .table-responsive {
    width: calc(100% - 132px);
  }
}
.table td {
  vertical-align: middle;
}
</style>
